import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from './locales/en/translation.json';
import translationBs from './locales/bs/translation.json';

const resources = {
  en: {translation: translationEn},
  bs: {translation: translationBs}
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: 'bs',
    fallbackLng: {
      'sr-RS': ['bs'],
      'hr': ['bs'],
      'me': ['bs'],
      default: ['en']
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources,
    initImmediate: false
  });

export default i18n;