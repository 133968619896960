import AxiosInstance from '../api-client/AxiosInstance';
import FileHelper from '../../helpers/file-helper/FileHelper';

const ENDPOINT = '/ceremonial-dresses';

const getDressCollection = (page = 1, pageSize = 10) => {
  return AxiosInstance.get(`${ENDPOINT}`, {params: {populate: 'images', pagination: {page, pageSize}, sort: ['id:desc']}})
    .then(response => response.data)
    .then(res => ({
      ...res,
      data: res.data.map(row => ({
        ...row.attributes,
        images: row.attributes.images.data.map(row => ({
          original: {
            width: row.attributes.width,
            height: row.attributes.height,
            url: FileHelper.getFileUrl(row.attributes.url)
          },
          large: {
            width: row.attributes.formats.large.width,
            height: row.attributes.formats.large.height,
            url: FileHelper.getFileUrl(row.attributes.formats.large.url)
          },
          thumbnail: {
            width: row.attributes.formats.thumbnail.width,
            height: row.attributes.formats.thumbnail.height,
            url: FileHelper.getFileUrl(row.attributes.formats.thumbnail.url)
          }
        }))
      }))
    }));
};

const CeremonialDressCollectionService = {
  getDressCollection
};

export default CeremonialDressCollectionService;
