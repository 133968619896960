const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const getFileUrl = (filePath) => {
  return `${BACKEND_URL}${filePath}`;
};

const FileHelper = {
  getFileUrl
};

export default FileHelper;