import {Carousel as MaterialCarousel, IconButton} from '@material-tailwind/react';

const Carousel = ({images, showArrows = true, onClick, className}) => {

  const onCLickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <MaterialCarousel
      className={`w-full h-full ${className ? className : ''}`}
      onClick={onCLickHandler}
      autoplay
      loop
      prevArrow={({handlePrev}) => (
        showArrows &&
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={event => {
            event.stopPropagation();
            handlePrev();
          }}
          className="!absolute top-2/4 left-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </IconButton>
      )}
      nextArrow={({handleNext}) => (
        showArrows &&
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={event => {
            event.stopPropagation();
            handleNext();
          }}
          className="!absolute top-2/4 !right-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </IconButton>
      )}
      navigation={({setActiveIndex, activeIndex, length}) => {
      }}
    >
      {
        images.map((image, index) => (
          <img src={image.src} alt={image.alt} key={index}
               className="h-full w-full object-cover"/>
        ))
      }
    </MaterialCarousel>
  );
};

export default Carousel;
