import CollectionPreview from '../../shared/components/collection-preview/CollectionPreview';
import TwoLayerCarousel from '../../shared/components/carousel/TwoLayerCarousel';
import ScrollDownButton from '../../shared/components/scroll-down-button/ScrollDownButton';
import {useEffect, useRef, useState} from 'react';
import {Typography} from '@material-tailwind/react';
import {AnimatePresence} from 'framer-motion';
import ContinuousMoveRight from '../../shared/animations/continuous-move-right/ContinuousMoveRight';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Button from '../../shared/components/button/Button';
import InstagramGallery from '../../shared/components/instagram-gallery/InstagramGallery';
import WeddingDressCollectionService from '../../shared/services/wedding-dresses/WeddingDressCollectionService';
import LoadingOverlay from '../../shared/components/loading-overlay/LoadingOverlay';
import ReactGA from 'react-ga4';

const Home = () => {

  ReactGA.send({hitType: 'pageview', page: '/', title: 'Homepage'});

  const {t, i18n} = useTranslation();

  const navigate = useNavigate();

  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const collections = await WeddingDressCollectionService.getWeddingDressCollections({showOnHomepage: {$eq: true}}, 'coverImage,wedding_dresses,wedding_dresses.images,video');
    setCollections(collections);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [i18n.language]);

  const collectionRef = useRef(null);

  const headingImages = [
    {
      id: 1,
      backgroundImage: 'images/background1.jpg',
      modelImage: 'images/model1.png'
    },
    {
      id: 2,
      backgroundImage: 'images/background2.jpg',
      modelImage: 'images/model2.png'
    }
  ];

  // const scrollDownHandler = () => {
  //   collectionRef.current?.scrollIntoView({behavior: 'smooth'});
  // };

  const heroLinkClickedHandler = () => {
    navigate('/wedding-dresses/first-love');
  };

  return (
    isLoading ? <LoadingOverlay/> :
      <>
        <span className={'relative flex w-full h-screen overflow-hidden'}>
        {/*<ScrollDownButton onClick={scrollDownHandler}*/}
        {/*                  className={'absolute z-10 left-0 right-0 mx-auto bottom-8 md:left-auto md:bottom-7 md:right-8'}/>*/}
        <TwoLayerCarousel images={headingImages} autoplayInterval={5000} className={'w-full h-full mx-auto'}/>
        <div className={'absolute left-0 right-0 bottom-24 flex flex-col items-center lg:hidden'}>
          <Typography
            className={'font-serif font-extralight text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.6)] -ml-10 md:ml-0 md:text-3xl'}
            variant={'h5'}
            onClick={heroLinkClickedHandler}
          >
              Nova kolekcija
            </Typography>
          <Typography
            className={'font-serif font-extralight text-white drop-shadow-[0_3px_3px_rgba(0,0,0,0.6)] -ml-16 md:-ml-20 text-6xl md:text-7xl -mb-3 md:-mb-2'}
            variant={'h1'}
            onClick={heroLinkClickedHandler}
          >
              First
            </Typography>
          <Typography
            className={'font-serif font-extralight text-white drop-shadow-[0_3px_3px_rgba(0,0,0,0.6)] text-6xl md:text-7xl ml-24'}
            variant={'h1'}
            onClick={heroLinkClickedHandler}
          >
              Love
            </Typography>
          <Button
            className={'mt-5 font-light text-deep-taupe bg-opacity-40 bg-white normal-case border-none'}
            onClick={() => navigate('/contact-us')}>
            {t('Make an Appointment')}
          </Button>
        </div>
        <div
          className={'absolute w-full h-60 left-32 top-1/4 my-auto hidden lg:block'}>
          <AnimatePresence mode={'wait'}>
            <ContinuousMoveRight duration={80}>
              <Typography
                className={'font-serif font-extralight text-white drop-shadow-[0_3px_3px_rgba(0,0,0,0.6)] cursor-pointer'}
                variant={'h2'}
                onClick={heroLinkClickedHandler}
              >
                {t('New collection')}
              </Typography>
            </ContinuousMoveRight>
          </AnimatePresence>
          <AnimatePresence mode={'wait'}>
            <ContinuousMoveRight duration={90}>
              <Typography
                className={'font-serif font-extralight text-white drop-shadow-[0_5px_5px_rgba(0,0,0,0.6)] text-9xl -mb-6 cursor-pointer'}
                variant={'h1'}
                onClick={heroLinkClickedHandler}
              >
                First
              </Typography>
            </ContinuousMoveRight>
          </AnimatePresence>
          <AnimatePresence mode={'wait'}>
            <ContinuousMoveRight duration={80}>
              <Typography
                className={'font-serif font-extralight text-white drop-shadow-[0_5px_5px_rgba(0,0,0,0.6)] text-9xl ml-32  cursor-pointer'}
                variant={'h1'}
                onClick={heroLinkClickedHandler}
              >
                Love
              </Typography>
            </ContinuousMoveRight>
          </AnimatePresence>
          <Button
            className={'w-80 mt-5 text-1xl font-light text-deep-taupe bg-opacity-60 bg-white normal-case border-none hover:bg-opacity-100 hover:bg-white hover:text-deep-taupe'}
            onClick={() => navigate('/contact-us')}>
            {t('Make an Appointment')}
          </Button>
        </div>

      </span>
        <div className={'bg-cultured'}>
          {
            collections.map((collection, index) => (
              <CollectionPreview collection={collection} invertOrder={index % 2 === 1}
                                 ref={index === 0 ? collectionRef : undefined}
                                 className={'container mx-auto'}
                                 key={collection.name}/>
            ))
          }
        </div>
        <div className={'mx-auto'}>
          <InstagramGallery/>
        </div>
      </>
  );
};

export default Home;
