import {Routes, Route, useLocation} from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home';
import AboutUs from './pages/about-us/AboutUs';
import ContactUs from './pages/contact-us/ContactUs';
import Footer from './shared/components/footer/Footer';
import Collections from './pages/collections/Collections';
import CollectionDetails from './pages/collection-details/CollectionDetails';
import ItemDetails from './pages/item-details/ItemDetails';
import ScrollToTop from './shared/components/scroll-to-top/ScrollToTop';
import CeremonialDresses from './pages/ceremonial-dresses/CeremonialDresses';
import Inspiration from './pages/inspiration/Inspiration';
import Headroom from 'react-headroom';
import Navbar from './shared/components/navbar/Navbar';
import ReactGA from 'react-ga4';

function App() {
  const location = useLocation();

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);

  return (
    <div>
      <ScrollToTop/>
      <Headroom className={'absolute z-50 top-0 left-0 w-full'}>
        <Navbar className={'w-full px-4 md:px-16'}/>
      </Headroom>
      <Routes location={location} key={location.pathname}>
        <Route index element={<Home/>}/>
        <Route path={'/about-us'} element={<AboutUs/>}/>
        <Route path={'/contact-us'} element={<ContactUs/>}/>
        <Route path={'/wedding-dresses'} element={<Collections/>}/>
        <Route path={'/wedding-dresses/:collectionName'} element={<CollectionDetails/>}/>
        <Route path={'/wedding-dresses/:collectionName/:itemName'} element={<ItemDetails/>}/>
        <Route path={'/ceremonial-dresses'} element={<CeremonialDresses/>}/>
        <Route path={'/inspiration'} element={<Inspiration/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
