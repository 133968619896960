import React, {useRef} from 'react';
import {Typography} from '@material-tailwind/react';
import Breadcrumbs from '../../shared/components/breadcrumbs/Breadcrumbs';
import {useTranslation} from 'react-i18next';
import ScrollDownButton from '../../shared/components/scroll-down-button/ScrollDownButton';
import GridNumOfColumnsChanger from '../../shared/components/grid-num-of-columns-changer/GridNumOfColumnsChanger';
import {AnimatePresence} from 'framer-motion';
import FadeInWhenVisible from '../../shared/animations/fade-in-when-visible/FadeInWhenVisible';
import {useNavigate} from 'react-router-dom';
import ReactGA from 'react-ga4';

const AboutUs = () => {

  ReactGA.send({hitType: 'pageview', page: '/about-us', title: 'About Us'});

  const {t} = useTranslation();

  const navigate = useNavigate();

  const pageInfoRef = useRef(null);

  const breadcrumbPath = [
    {name: t('Home'), link: '/'},
    {name: t('About Us'), link: ''}
  ];


  const scrollDownHandler = () => {
    pageInfoRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <div>
      <div className={'relative flex flex-col w-full lg:h-screen'}>
          <span className={'relative w-full h-64 sm:h-80 md:h-96 lg:h-full overflow-hidden mt-12'}>
            <img src={'/images/ceremonial-dress-cover.jpg'} alt={'Ceremonial Dresses Cover Image'}
                 className={'w-full h-full object-cover block'}/>
            <ScrollDownButton onClick={scrollDownHandler}
                              className={'hidden lg:block lg:absolute z-10 left-0 right-0 mx-auto bottom-8 md:left-auto md:bottom-7 md:right-8'}/>
            <div
              className={'absolute w-full h-40 lg:h-80 bottom-0 bg-gradient-to-t from-[rgba(255,255,255,1)] to-[rgba(255,255,255,0)]'}>
            </div>
          </span>
        <span className={'w-full lg:absolute left-0 right-0 bottom-0 mx-auto -mt-20 lg:-mb-10 lg:pt-12'}
              ref={pageInfoRef}>
            <div className={'flex flex-col items-center'}>
              <Typography
                className={'font-serif font-extralight drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)] text-white text-center text-4xl lg:text-8xl mb-5'}
                variant={'h1'}
              >
                Naša priča
              </Typography>
              {/*<Breadcrumbs elements={breadcrumbPath}*/}
              {/*             className={'mx-auto w-full my-5 bg-white bg-opacity-100 drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]'}/>*/}
              <span className={'h-10'}>

              </span>
            </div>
          </span>
      </div>
      <div className={'container mx-auto pt-10 px-2 lg:px-0'}>
        <div className={'grid grid-cols-2 lg:grid-cols-4 gap-3'}>

          <span className={'flex flex-col justify-center gap-2 col-span-2 lg:ml-0 lg:mr-10 order-last lg:order-first'}>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1} fromDirection={'bottom'} delay={0.3} key={'collectionName'}>
                <Typography className={'font-serif font-light text-deep-taupe text-3xl md:text-4xl hidden lg:block'}>
                  {t('About Us')}
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.2} fromDirection={'bottom'} delay={0.5} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2 jus'} variant={'paragraph'}>
                  Studio Divine je plod iskrene ljubavi prema nezaboravnim trenucima i bajkovitim ceremonijama.
                  Naša priča počinje iz srca, s ciljem stvaranja prostora gdje se snovi o bajkovitim vjenčanjima i posebnim
                  događajima pretvaraju u stvarnost.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.3} fromDirection={'bottom'} delay={0.7} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2'} variant={'paragraph'}>
                  U vjerovanju da svaki trenutak zaslužuje posebnu pažnju i eleganciju, Studio Divine je osmišljen
                  kao prostor u kojem biranje i prilagođavanje svečanih haljina i vjenčanica postaju nevjerojatno iskustvo.
                  Svaka haljina u našoj kolekciji pažljivo je odabrana, a naša strast prema luksuznim materijalima,
                  besprijekornom kroju i pažljivim detaljima odražava se u svakom dizajnu.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.4} fromDirection={'bottom'} delay={0.9} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2'} variant={'paragraph'}>
                  Naš tim ljubaznih i iskusnih stručnjaka posvećen je tome da svaka žena pronađe svoju savršenu haljinu,
                  bilo da je riječ o vjenčanju iz snova ili nekom drugom svečanom događaju. Nudimo personaliziran pristup
                  svakoj klijentici, jer vjerujemo da svaka žena ima svoju jedinstvenu ljepotu koja treba zasjati u posebnim trenucima.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.5} fromDirection={'bottom'} delay={1.1} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2'} variant={'paragraph'}>
                  Studio Divine nije samo salon, već i zajednica ljubitelja elegancije, gdje dijelimo radost stvaranja
                  nezaboravnih trenutaka s našim klijenticama. Uz nas, svaki korak prema vašem posebnom trenutku postaje magično iskustvo.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
          </span>
          <span className={'flex flex-col justify-end'}>
             <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1} fromDirection={'bottom'} delay={0.3} key={'collectionName'}>
                <Typography className={'mb-10 font-serif font-light text-deep-taupe text-3xl md:text-4xl lg:hidden'}>
                  {t('About Us')}
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <div
              className={'w-full overflow-hidden'}>
              <AnimatePresence mode={'wait'}>
                <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={1.1} key={'collectionName'}>
                  <img src={'/logo.svg'} className={'rounded-lg'}/>
                </FadeInWhenVisible>
              </AnimatePresence>
            </div>
          </span>
          <span className={'flex flex-col gap-3'}>
            <div
              className={'w-full overflow-hidden'}>
              <AnimatePresence mode={'wait'}>
                <FadeInWhenVisible duration={1.8} fromDirection={'top'} delay={1.1} key={'collectionName'}>
                  <img src={'/images/about-us-1.jpg'} className={'rounded-lg'}/>
                </FadeInWhenVisible>
              </AnimatePresence>
            </div>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={1.1} key={'collectionName'}>
                <div className={'h-20 md:h-48 border border-deep-taupe flex justify-center items-center rounded-lg'}>
                  <Typography
                    className={'font-serif text-deep-taupe text-lg md:text-2xl py-1 border-b border-b-white hover:border-b-deep-taupe transition-all cursor-pointer'}
                    onClick={() => navigate('/contact-us')}>
                    {t('Contact Us')}
                  </Typography>
                </div>
              </FadeInWhenVisible>
            </AnimatePresence>
          </span>
        </div>
        <div className={'grid grid-cols-2 lg:grid-cols-4 gap-3 mt-10'}>
          <span className={'flex flex-col justify-center gap-2 col-span-2 lg:ml-10 order-last'}>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1} fromDirection={'bottom'} delay={0.3} key={'collectionName'}>
                <Typography className={'font-serif font-light text-deep-taupe text-3xl md:text-4xl hidden lg:block'}>
                  Divine Bridal Couture
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.2} fromDirection={'bottom'} delay={0.5} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2'} variant={'paragraph'}>
                  Iz duboke ljubavi prema vjenčanicama, dajemo svoj jedinstveni pečat brendu Divine Bridal Couture,
                  upotpunjujući našu misiju pružanja izvanrednih kreacija koje ističu individualnost i ljepotu svake mladenke.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.3} fromDirection={'bottom'} delay={0.7} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2'} variant={'paragraph'}>
                  Divine Bridal Couture, ponosno predstavlja bosanskohercegovački modni brend luksuznih dizajnerskih vjenčanica.
                  Simbolično je osnovan na Dan zaljubljenih, i od tada je poznat kao nezaobilazno utočište ljubitelja bezvremenskih
                  romantičnih kreacija.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.4} fromDirection={'bottom'} delay={0.9} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2'} variant={'paragraph'}>
                  Naziv brenda Divine inspiriran je latinskom riječju "dīvīnus", s old-fashioned i neformalnim značenjem
                  biti vrlo dobar ili ugodan, božanski. Upravo u tom duhu, postavljena je vizija našeg brenda -
                  stvaranje vjenčanica koje naglašavaju individualnost i bezvremensku ljepotu žene.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.5} fromDirection={'bottom'} delay={1.1} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2'} variant={'paragraph'}>
                  Divine Bridal Couture odlikuje besprijekorna izrada vjenčanica koja pomiče granice ručne izrade
                  delikatnih motiva nježne čipke i perli, jasnih linija, zavodljivih krojeva i elegantnih silueta.
                  Posvećujemo pažnju najfinijim detaljima, postavljajući svaki komad s preciznošću. Naša misija je
                  stvaranje zadivljujućih kreacija, gdje svaki komad utjelovljuje odvažne i senzualne siluete.
                  Svaka vjenčanica je jedinstvena jer je dizajnirana kao unikatna kreacija.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
          </span>
          <span className={'flex flex-col justify-end'}>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1} fromDirection={'bottom'} delay={0.3} key={'collectionName'}>
                <Typography className={'font-serif font-light text-deep-taupe text-3xl md:text-4xl lg:hidden'}>
                  Divine Bridal Couture
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <div
              className={'w-full overflow-hidden'}>
              <AnimatePresence mode={'wait'}>
                <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={1.1} key={'collectionName'}>
                  <img src={'/images/inspo3.jpg'} className={'rounded-lg'}/>
                </FadeInWhenVisible>
              </AnimatePresence>
            </div>
          </span>
          <span className={'flex flex-col gap-3 lg:order-first'}>
            <div
              className={'mt-10 w-full overflow-hidden'}>
              <AnimatePresence mode={'wait'}>
                <FadeInWhenVisible duration={1.8} fromDirection={'top'} delay={1.1} key={'collectionName'}>
                  <img src={'/images/inspo3.jpg'} className={'rounded-lg'}/>
                </FadeInWhenVisible>
              </AnimatePresence>
            </div>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={1.1} key={'collectionName'}>
                <div className={'h-20 md:h-48 border border-deep-taupe flex justify-center items-center rounded-lg'}>
                  <Typography
                    className={'font-serif text-deep-taupe text-lg md:text-2xl py-1 border-b border-b-white hover:border-b-deep-taupe transition-all cursor-pointer'}
                    onClick={() => navigate('/')}>
                    {t('Home')}
                  </Typography>
                </div>
              </FadeInWhenVisible>
            </AnimatePresence>
          </span>
        </div>
        <div className={'grid grid-cols-2 lg:grid-cols-4 gap-3 mt-10'}>
          <span className={'flex flex-col justify-center gap-2 col-span-2 lg:mr-10 order-last lg:order-first'}>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1} fromDirection={'bottom'} delay={0.3} key={'collectionName'}>
                <Typography className={'font-serif font-light text-deep-taupe text-3xl md:text-4xl hidden lg:block'}>
                  Naš tim
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.2} fromDirection={'bottom'} delay={0.5} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2'} variant={'paragraph'}>
                  Studio Divine tim čvrsto vjeruje i osnažuje vjerovanje u filozofiju ljubavi prema život i poslu koji radite.
                  Veoma smo ponosni što smo uspjeli okupiti profesionalce istomišljenike, koji se sa iskrenim entuzijazmom
                  bave svakodnevnim poslovima.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.3} fromDirection={'bottom'} delay={0.7} key={'collectionName'}>
                <Typography className={'text-deep-taupe text-sm mt-2'} variant={'paragraph'}>
                  Sve što naš tim radi je za žene i o ženama, jer su žena naša glavna inspiracija za dizajniranje i
                  stvaranje novih kreacija. Da bi svoje ideale i želje ostvarili, tim Divine Bridal Couture s velikom
                  posvećenošću svakodnevno prati aktuelne svjetske modne trendove. Koristeći ih za generisanje svježih
                  ideja i modernih rješenja, a zatim ih primjenjujemo na naše najnovije kreacije.
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
          </span>
          <span className={'flex flex-col justify-end'}>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1} fromDirection={'bottom'} delay={0.3} key={'collectionName'}>
                <Typography className={'font-serif font-light text-deep-taupe text-3xl md:text-4xl lg:hidden'}>
                  Naš tim
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <div
              className={'w-full overflow-hidden'}>
              <AnimatePresence mode={'wait'}>
                <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={1.1} key={'collectionName'}>
                  <img src={'/images/inspo1.jpg'} className={'rounded-lg'}/>
                </FadeInWhenVisible>
              </AnimatePresence>
            </div>
          </span>
          <span className={'flex flex-col justify-end'}>
            <div
              className={'w-full overflow-hidden'}>
              <AnimatePresence mode={'wait'}>
                <FadeInWhenVisible duration={1.8} fromDirection={'top'} delay={1.1} key={'collectionName'}>
                  <img src={'/images/inspo3.jpg'} className={'rounded-lg'}/>
                </FadeInWhenVisible>
              </AnimatePresence>
            </div>
          </span>
        </div>
        <div className={'flex justify-center items-center'}>
          <AnimatePresence mode={'wait'}>
            <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={0.2} key={'collectionName'}>
              <Typography className={'font-serif font-light text-center text-lg md:text-2xl text-deep-taupe my-16 lg:mx-10'}>
                Zaronite u čarobni svijet jedinstvenih dizajnerskih haljina!
              </Typography>
            </FadeInWhenVisible>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
