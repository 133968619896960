import AxiosInstance from '../api-client/AxiosInstance';
import FileHelper from '../../helpers/file-helper/FileHelper';

const ENDPOINT = '/wedding-dresses';

const getWeddingDress = (slug) => {
  const paramsObj = {populate: 'video'};

  return AxiosInstance.get(`${ENDPOINT}/${slug}`, {params: paramsObj})
    .then(response => response.data.data)
    .then(data => ({
      ...data.attributes,
      images: data.attributes.images.data.map(row => ({
        original: {
          width: row.attributes.width,
          height: row.attributes.height,
          url: FileHelper.getFileUrl(row.attributes.url)
        },
        large: {
          width: row.attributes.formats.large.width,
          height: row.attributes.formats.large.height,
          url: FileHelper.getFileUrl(row.attributes.formats.large.url)
        },
        thumbnail: {
          width: row.attributes.formats.thumbnail.width,
          height: row.attributes.formats.thumbnail.height,
          url: FileHelper.getFileUrl(row.attributes.formats.thumbnail.url)
        },
      })),
      video: data.attributes.video && data.attributes.video.data && FileHelper.getFileUrl(data.attributes.video.data.attributes.url),
    }));
};

const WeddingDressService = {
  getWeddingDress
};

export default WeddingDressService;
