import React, {useRef, useState} from 'react';
import {Card, CardBody, Input, Textarea, Typography} from '@material-tailwind/react';
import Button from '../button/Button';
import {useTranslation} from 'react-i18next';
import ContactUsService from '../../services/contact-us/ContactUsService';
import ReactGA from 'react-ga4';
import {AnimatePresence} from 'framer-motion';
import FadeInWhenVisible from '../../animations/fade-in-when-visible/FadeInWhenVisible';

const ContactForm = ({className}) => {
  const formRef = useRef(null);

  const {t} = useTranslation();

  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {};
    const elements = event.target.elements;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
        formData[element.name] = element.value;
      }
    }

    if (!(formData.name && formData.email && formData.message)) {
      alert(`Molimo popunite sva polja.`);
      return;
    }

    setIsSending(true);

    try {
      await ContactUsService.sendMessage(formData);
      ReactGA.event({
        category: 'Contact Us form',
        action: 'contact_us_form_submission',
      });
      setIsSent(true);
    } catch (error) {
      ReactGA.event({
        category: 'Contact Us form',
        action: 'contact_us_form_submission_error',
      });
    }

    setIsSending(false);
  };

  const handleSubmitButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', {cancelable: true, bubbles: true})
      );
    }
  };

  return (
    <div className={`md:col-span-2 flex justify-center ${className ? className : ''}`}>
      <Card className={'w-full mx-4 md:m-10 md:w-2/3 shadow-none border border-deep-taupe'}>
        <CardBody className={'flex flex-col'}>
          <Typography className={'text-xl text-deep-taupe font-serif font-extralight tracking-wide mb-5'}>
            {t('Send us a message')}
          </Typography>
          <form className={'w-full grid grid-cols-1 md:grid-cols-2 gap-4'} ref={formRef} onSubmit={handleSubmit}>
            <Input
              size={'md'}
              label={t('Name')}
              name={'name'}
              color={'brown'}
            />
            <Input
              size={'md'}
              label={t('Email')}
              name={'email'}
              color={'brown'}
            />
            <span className={'md:col-span-2'}>
              <Textarea
                label={t('Message')}
                className={'w-full'}
                name={'message'}
                color={'brown'}
              />
            </span>
          </form>
          <Button className={'mt-2 self-end normal-case'} onClick={handleSubmitButtonClick} disabled={isSending || isSent}>
            {t('Send')}
          </Button>
          {
            isSent &&
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1} fromDirection={'bottom'} animationDistance={10} delay={0.3}
                                 key={'collectionName'}>
                <Typography className={'font-serif font-extralight text-sm text-deep-taupe text-center mt-5'}>
                  Vaša poruka je uspješno poslata!
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
          }
        </CardBody>
      </Card>
    </div>
  );
};

export default ContactForm;
