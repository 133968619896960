import {motion} from 'framer-motion';

const FadeInWhenVisible = ({children, duration, delay, fromDirection, animationDistance, className}) => {

  const distance = animationDistance ? animationDistance : 50;

  const getVariants = () => {
    switch (fromDirection) {
      case 'top':
        return {
          visible: {opacity: 1, y: 0},
          hidden: {opacity: 0, y: -distance}
        };
      case 'bottom':
        return {
          visible: {opacity: 1, y: 0},
          hidden: {opacity: 0, y: distance}
        };
      case 'left':
        return {
          visible: {opacity: 1, x: 0},
          hidden: {opacity: 0, x: -distance}
        };
      case 'right':
        return {
          visible: {opacity: 1, x: 0},
          hidden: {opacity: 0, x: distance}
        };
      default:
        return {
          visible: {opacity: 1, y: 0},
          hidden: {opacity: 0, y: distance}
        };
    }
  };

  const variants = getVariants();

  return (
    <motion.div
      initial={'hidden'}
      whileInView={'visible'}
      viewport={{once: true}}
      exit={'hidden'}
      transition={{duration: duration, delay: delay}}
      variants={variants}
      className={`${className ? className : ''}`}
    >
      {children}
    </motion.div>
  );
};

export default FadeInWhenVisible;