import {motion} from 'framer-motion';

const ZoomInAndOverlayImage = ({image, alt, className}) => {
  return (
    <motion.div
      whileHover={{scale: 1.05}}
      transition={{duration: 0.6, delay: 0.15}}
      className={`${className ? className : ''}`}
    >
      <motion.img
        src={image}
        alt={alt}
        className={'w-full h-full object-cover'}
        loading={'lazy'}
      />
      <motion.div
        whileHover={{opacity: 1}}
        className={'absolute top-0 left-0 w-full h-full opacity-0 to-bg-black-80 inset-0 bg-gradient-to-t from-black/80 via-black/40'}
      />
    </motion.div>
  );
};

export default ZoomInAndOverlayImage;
