import GoogleMap from '../../shared/components/google-map/GoogleMap';
import {Card, CardBody, Input, Textarea, Typography} from '@material-tailwind/react';
import Button from '../../shared/components/button/Button';
import {useTranslation} from 'react-i18next';
import Breadcrumbs from '../../shared/components/breadcrumbs/Breadcrumbs';
import FadeInWhenVisible from '../../shared/animations/fade-in-when-visible/FadeInWhenVisible';
import {AnimatePresence} from 'framer-motion';
import ReactGA from 'react-ga4';
import ContactForm from '../../shared/components/contact-form/ContactForm';

const ContactUs = () => {

  ReactGA.send({hitType: 'pageview', page: '/contact-us', title: 'Contact Us'});

  const {t} = useTranslation();

  return (
    <div>
      <div className={'container mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 mt-20'}>
        <AnimatePresence mode={'wait'}>
          <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={0.5} key={'collectionName'}>
            <img className={'md:rounded-lg md:h-240 w-full'} src={'images/salon.jpg'} alt={'Studio image'}/>
          </FadeInWhenVisible>
        </AnimatePresence>
        <div className={'flex flex-col md:items-center mx-4 md:mx-0 justify-center'}>
          <span className={'flex flex-col'}>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={0.7} key={'collectionName'}>
                <span className={'mb-5'}>
                  <Typography
                    className={'font-serif font-normal tracking-wide text-deep-taupe text-2xl md:text-3xl'}>
                    Showroom
                  </Typography>
                  <Typography
                    className={'font-serif font-normal tracking-widest text-deep-taupe text-2xl md:text-3xl ml-16'}
                  >
                    Studio Divine
                  </Typography>
                </span>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={0.9} key={'collectionName'}>
                <span className={'flex flex-col items-start'}>
                  <Typography
                    className={'font-normal text-justify md:text-lg leading-relaxed text-deep-taupe'}
                    variant={'paragraph'}
                  >
                    Adema Buće 13
                  </Typography>
                  <Typography
                    className={'font-normal text-justify md:text-lg leading-relaxed text-deep-taupe'}
                    variant={'paragraph'}
                  >
                    71000 Sarajevo
                  </Typography>
                  <Typography
                    className={'font-normal text-justify md:text-lg leading-relaxed text-deep-taupe border-b border-deep-taupe pb-2 mb-2 w-full'}
                    variant={'paragraph'}
                  >
                    Bosna i Hercegovina
                  </Typography>
                  <Typography
                    className={'font-normal text-justify md:text-lg leading-relaxed text-deep-taupe'}
                    variant={'paragraph'}
                  >
                    Mob.: +387 61 978 477
                  </Typography>
                  <Typography
                    className={'font-normal text-justify md:text-lg leading-relaxed text-deep-taupe'}
                    variant={'paragraph'}
                  >
                    E-mail: studiodivine19@outlook.com
                  </Typography>
                </span>
              </FadeInWhenVisible>
            </AnimatePresence>
          </span>
        </div>
        <div className={'flex flex-col justify-center md:items-center mx-5 md:mx-0'}>
          <AnimatePresence mode={'wait'}>
            <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={1.2} key={'collectionName'}>
              <span className={'flex flex-col'}>
            <Typography
              className={'font-serif font-normal tracking-widest text-deep-taupe text-2xl md:text-3xl mb-3'}
            >
              Radno vrijeme
            </Typography>
            <Typography
              className={'font-normal md:text-lg leading-none text-deep-taupe'}
              variant={'paragraph'}
            >
              Ponedjeljak - Petak
            </Typography>
            <Typography
              className={'font-serif font-normal md:text-lg leading-relaxed text-deep-taupe md:mb-2'}
              variant={'paragraph'}
            >
              12h - 19h
            </Typography>
            <Typography
              className={'font-normal md:text-lg leading-relaxed text-deep-taupe'}
              variant={'paragraph'}
            >
              Subota
            </Typography>
            <Typography
              className={'font-serif font-normal md:text-lg leading-none text-deep-taupe'}
              variant={'paragraph'}
            >
              10h - 16h
            </Typography>
          </span>
            </FadeInWhenVisible>
          </AnimatePresence>
        </div>
        <AnimatePresence mode={'wait'}>
          <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={0.5} key={'collectionName'}>
            <div>
              <GoogleMap className={'md:rounded-lg w-full h-[20rem] md:h-[25rem]'}
                         location={'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11509.502308924315!2d18.3763195!3d43.8480436!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4758c99bb61ba755%3A0xec7c6aa0535e6929!2sStudio%20Divine!5e0!3m2!1s!2sba!4v1705174648819!5m2!1s!2sba'}/>
            </div>
          </FadeInWhenVisible>
        </AnimatePresence>
      </div>
      <AnimatePresence mode={'wait'}>
        <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={0.9} key={'collectionName'}>
          <ContactForm className={'mt-10'}/>
        </FadeInWhenVisible>
      </AnimatePresence>
    </div>
  );
};

export default ContactUs;
