import {DialogBody, DialogHeader, IconButton, Input, Typography} from '@material-tailwind/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useTranslation} from 'react-i18next';
import UploadYourStoryForm from './UploadYourStoryForm';
import React from 'react';

const UploadYourStoryDialog = ({onClose}) => {

  const {t} = useTranslation();

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <DialogHeader className={'px-4 py-2 lg:py-4 border-b border-deep-taupe justify-between'}>
        <Typography
          className={'font-serif font-normal tracking-wide text-deep-taupe'}
          variant={'h5'}
        >
          Pošaljite Vašu priču
        </Typography>
        <IconButton variant={'text'} className={'rounded-full text-deep-taupe'} onClick={onCloseHandler}>
          <XMarkIcon className={'h-5'}/>
        </IconButton>
      </DialogHeader>
      <DialogBody className={'h-[30rem] p-4 lg:p-8 rounded-none overflow-y-scroll'}>
        <Typography className={'text-deep-taupe text-center mb-5'} variant={'paragraph'}>
          {t('inspiration-hero-text')}
        </Typography>
        <UploadYourStoryForm/>
      </DialogBody>
    </>
  );
};

export default UploadYourStoryDialog;
