import ImageGallery from '../../shared/components/image-gallery/ImageGallery';
import {ButtonGroup, Dialog, Typography} from '@material-tailwind/react';
import {ArrowLongLeftIcon, ArrowLongRightIcon, ChevronLeftIcon, Squares2X2Icon} from '@heroicons/react/20/solid';
import ItemProperty from '../../shared/components/item-property/ItemProperty';
import {useEffect, useState} from 'react';
import SizeChartDialog from '../../shared/components/size-chart/SizeChartDialog';
import {useNavigate, useParams} from 'react-router-dom';
import WeddingDressService from '../../shared/services/wedding-dress/WeddingDressService';
import WeddingDressCollectionService from '../../shared/services/wedding-dresses/WeddingDressCollectionService';
import {useTranslation} from 'react-i18next';
import LoadingOverlay from '../../shared/components/loading-overlay/LoadingOverlay';
import Button from '../../shared/components/button/Button';
import ReactGA from 'react-ga4';
import MeasurementsDialog from '../../shared/components/measurements/MeasurementsDialog';
import VideoPlayer from '../../shared/components/video-player/VideoPlayer';

const ItemDetails = () => {

  const {t, i18n} = useTranslation();

  const {collectionName, itemName} = useParams();

  const navigate = useNavigate();

  const [item, setItem] = useState([]);
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const item = await WeddingDressService.getWeddingDress(itemName);
    const collection = await WeddingDressCollectionService.getWeddingDressCollection(collectionName, 'wedding_dresses');
    setItem(item);
    setCollection(collection);
    setIsLoading(false);
    ReactGA.send({hitType: 'pageview', page: `/wedding-dresses/${collection.slug}/${item.slug}`, title: item.name});
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [i18n.language]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogState = () => setDialogOpen(prevState => !prevState);

  const [dialogScreen, setDialogScreen] = useState(<SizeChartDialog onClose={handleDialogState}/>);

  const handleOpenSizeDialog = () => {
    setDialogScreen(<SizeChartDialog onClose={handleDialogState}/>);
    setDialogOpen(!dialogOpen);
  };

  const handleOpenMeasurementsDialog = () => {
    setDialogScreen(<MeasurementsDialog onClose={handleDialogState}/>);
    setDialogOpen(!dialogOpen);
  };

  const images = item.images ? item.images.map(image => ({...image, alt: ''})) : [];

  const itemPositionInCollection = collection.wedding_dresses ? collection.wedding_dresses.findIndex(dress => dress.name === item.name) : 0;
  const numberOfItemsInCollection = collection.wedding_dresses ? collection.wedding_dresses.length : 0;

  const openCollection = () => {
    navigate(`/wedding-dresses/${collectionName}`);
  };

  const openPrev = () => {
    const prevItem = collection.wedding_dresses[itemPositionInCollection - 1];
    navigate(`/wedding-dresses/${collectionName}/${prevItem.slug}`);
  };

  const openNext = () => {
    const nextItem = collection.wedding_dresses[itemPositionInCollection + 1];
    navigate(`/wedding-dresses/${collectionName}/${nextItem.slug}`);
  };

  const hasPrev = () => {
    if (!collection.wedding_dresses) {
      return false;
    }

    return itemPositionInCollection > 0;
  };

  const hasNext = () => {
    if (!collection.wedding_dresses) {
      return false;
    }

    return itemPositionInCollection < collection.wedding_dresses.length - 1;
  };

  const itemDescription = item.description ? item.description.split('\\') : [];

  return (
    <>
      {
        isLoading && <LoadingOverlay/>
      }
      <div>
        <div className={'container mx-auto mt-14 md:mt-20 lg:mt-24 grid gap-2 grid-cols-1 lg:grid-cols-3'}>
          <div
            className={'flex flex-col justify-start items-center col-span-1 px-12 order-last mt-5 lg:order-first lg:mt-0'}>
            <div className={'lg:flex w-full justify-between mb-5 items-center hidden'}>
              <Button size={'sm'}
                      onClick={openPrev} disabled={!hasPrev()}>
                <ArrowLongLeftIcon className={'h-4'}/>
              </Button>
              <Typography
                className={'font-light tracking-widest text-deep-taupe text-sm'}
                variant={'paragraph'}
              >
                {itemPositionInCollection + 1}/{numberOfItemsInCollection}
              </Typography>
              <Button size={'sm'}
                      className={'bg-white text-deep-taupe border border-deep-taupe hover:bg-deep-taupe hover:text-white'}
                      onClick={openNext} disabled={!hasNext()}>
                <ArrowLongRightIcon className={'h-4'}/>
              </Button>
            </div>
            <Typography
              className={'font-serif font-light tracking-widest text-deep-taupe border-b border-b-deep-taupe px-12 pb-4 mb-5'}
              variant={'h4'}
            >
              {item.name}
            </Typography>
            {
              itemDescription.map(description => (
                <p className={'font-light text-deep-taupe text-justify text-xs leading-relaxed'}>
                  {description}
                </p>
              ))
            }
            <ItemProperty name={t('Collection')} values={[collection.name]} className={'mt-5'}/>
            <div className={'flex w-full flex-wrap gap-2 justify-between mt-10 items-center'}>
              <Button
                className={'grow normal-case text-xs whitespace-nowrap'}
                onClick={handleOpenSizeDialog}>
                {t('Size guide')}
              </Button>
              <Button
                className={'grow normal-case'}
                onClick={handleOpenMeasurementsDialog}>
                {t('Take measurements')}
              </Button>
            </div>
            <Button
              className={'w-full mt-5'}
              onClick={() => navigate('/contact-us')}>
              {t('Make an Appointment')}
            </Button>

            <Button
              className={'lg:flex justify-center gap-2 items-center normal-case mt-5 hidden bg-opacity-10 border-0'}
              variant={'text'} onClick={openCollection}>
              <ChevronLeftIcon className={'h-5'}/> {t('To Catalog')}
            </Button>
          </div>
          <div className={'flex overflow-hidden col-span-2 px-2 md:px-0 lg:h-screen'}>
            <div className='lg:overflow-hidden lg:overflow-y-scroll flex flex-col gap-4'>
            <ImageGallery images={images} className={''}/>
            { 
             item.video && <VideoPlayer src={item.video}/>
            }
            </div>
            
          </div>
          
            
        </div>
      </div>
      <Dialog open={dialogOpen} handler={handleDialogState} className={'rounded-none w-full'} size={'xl'}>
        {dialogScreen}
      </Dialog>
      <div className={'fixed w-full bottom-0 z-50 lg:hidden'}>
        <ButtonGroup fullWidth={true} size={'lg'} color={'white'} className={'border-t border-t-deep-taupe bg-white'}>
          <Button
            className={'flex justify-center rounded-none'}
            onClick={openPrev} disabled={!hasPrev()}>
            <ArrowLongLeftIcon className={'h-5'}/>
          </Button>
          <Button
            className={'flex justify-center'}
            onClick={openCollection}>
            <Squares2X2Icon className={'h-5'}/>
          </Button>
          <Button
            className={'flex justify-center rounded-none'}
            onClick={openNext} disabled={!hasNext()}>
            <ArrowLongRightIcon className={'h-5'}/>
          </Button>
        </ButtonGroup>
      </div>
    </>

  );
};

export default ItemDetails;
