import {motion} from 'framer-motion';

const SwitchImagesOnHover = ({image1, image2, alt, className}) => {
  return (
    <motion.div
      whileHover={{scale: 1.05}}
      transition={{duration: 0.4, delay: 0.15}}
      className={`relative w-full h-full overflow-hidden ${className ? className : ''}`}
    >
      <motion.img
        src={image1}
        alt={alt}
        className={'w-full h-full object-cover'}
      />
      <motion.img
        src={image2}
        alt={alt}
        className={'w-full h-full object-cover absolute top-0 left-0 opacity-0'}
        whileHover={{opacity: 1, scale: 1.05}}
        transition={{duration: 0.4, delay: 0.15}}
      />
      <motion.div
        whileHover={{opacity: 1}}
        className={'absolute top-0 left-0 w-full h-full opacity-0 pointer-events-none'}
      />
    </motion.div>
  );
};

export default SwitchImagesOnHover;
