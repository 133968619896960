import AxiosInstance from '../api-client/AxiosInstance';
import FileHelper from '../../helpers/file-helper/FileHelper';
import axios from 'axios';

const ENDPOINT = '/inspiration-images';
const FORM_MAIL_ENDPOINT = `${process.env.REACT_APP_FORM_MAIL_URL}/submit-form`;

const getInspirationImages = (page = 1, pageSize = 10) => {
  return AxiosInstance.get(`${ENDPOINT}`, {params: {populate: 'image', pagination: {page, pageSize}, sort: ['id:desc']}})
    .then(response => (response.data))
    .then(res => ({
      ...res,
      data: res.data.map(row => ({
        ...row.attributes,
        image: {
          original: {
            width: row.attributes.image.data.attributes.width,
            height: row.attributes.image.data.attributes.height,
            url: FileHelper.getFileUrl(row.attributes.image.data.attributes.url)
          },
          large: {
            width: row.attributes.image.data.attributes.formats.large ? row.attributes.image.data.attributes.formats.large.width : row.attributes.image.data.attributes.width,
            height: row.attributes.image.data.attributes.formats.large ? row.attributes.image.data.attributes.formats.large.height : row.attributes.image.data.attributes.height,
            url: FileHelper.getFileUrl(row.attributes.image.data.attributes.formats.large ? row.attributes.image.data.attributes.formats.large.url : row.attributes.image.data.attributes.url)
          },
          thumbnail: {
            width: row.attributes.image.data.attributes.formats.thumbnail ? row.attributes.image.data.attributes.formats.thumbnail.width : row.attributes.image.data.attributes.width,
            height: row.attributes.image.data.attributes.formats.thumbnail ? row.attributes.image.data.attributes.formats.thumbnail.height : row.attributes.image.data.attributes.height,
            url: FileHelper.getFileUrl(row.attributes.image.data.attributes.formats.thumbnail ? row.attributes.image.data.attributes.formats.thumbnail.url : row.attributes.image.data.attributes.url)
          }
        }
      }))
    }));
};

const sendInspirationImages = (data) => {
  return axios.post(FORM_MAIL_ENDPOINT, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const InspirationImagesService = {
  getInspirationImages,
  sendInspirationImages
};

export default InspirationImagesService;
