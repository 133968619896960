import {Typography} from '@material-tailwind/react';
import {AnimatePresence} from 'framer-motion';
import CollectionItemPreviewCard from '../../shared/components/collection-item-preview-card/CollectionItemPreviewCard';
import React, {useEffect, useRef, useState} from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import 'yet-another-react-lightbox/plugins/counter.css';
//import {Thumbnails} from 'yet-another-react-lightbox/plugins';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import CeremonialDressCollectionService
  from '../../shared/services/ceremonial-dresses/CeremonialDressCollectionService';
import {useTranslation} from 'react-i18next';
import LoadingOverlay from '../../shared/components/loading-overlay/LoadingOverlay';
import GridNumOfColumnsChanger from '../../shared/components/grid-num-of-columns-changer/GridNumOfColumnsChanger';
import ScrollDownButton from '../../shared/components/scroll-down-button/ScrollDownButton';
import FadeInWhenVisible from '../../shared/animations/fade-in-when-visible/FadeInWhenVisible';
import ReactGA from 'react-ga4';
import Button from '../../shared/components/button/Button';

const CeremonialDresses = () => {

  ReactGA.send({hitType: 'pageview', page: '/ceremonial-dresses', title: 'Ceremonial Dresses'});

  const {t, i18n} = useTranslation();

  const [dresses, setDresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAdditional, setIsLoadingAdditional] = useState(false);
  const [page, setPage] = useState(1);
  const [totalDresses, setTotalDresses] = useState();

  const fetchData = async (page) => {
    const fetchedData = await CeremonialDressCollectionService.getDressCollection(page, 12);

    const fetchedDresses = fetchedData.data;

    setTotalDresses(fetchedData.meta.pagination.total);

    setDresses(prevState => ([...prevState, ...fetchedDresses]));
    setIsLoading(false);
  };

  const loadMoreDresses = async () => {
    setIsLoadingAdditional(true);
    await fetchData(page + 1);
    setPage(page + 1);
    setIsLoadingAdditional(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [i18n.language]);


  const hasMoreToLoad = () => {
    return dresses.length < totalDresses;
  };

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);

  const [gridColsOptions, setGridColsOptions] = useState('sm:grid-cols-3');

  const pageInfoRef = useRef(null);

  const openLightbox = (images) => {
    setLightboxImages(images.map(image => ({
      src: image.original.url,
      width: image.original.width,
      height: image.original.height,
      srcSet: [
        {
          src: image.thumbnail.url,
          width: image.thumbnail.width,
          height: image.thumbnail.height
        },
        {
          src: image.large.url,
          width: image.large.width,
          height: image.large.height
        },
        {
          src: image.original.url,
          width: image.original.width,
          height: image.original.height
        }
      ]
    })));
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxImages([]);
    setLightboxOpen(false);
  };

  const breadcrumbPath = [
    {name: t('Home'), link: '/'},
    {name: t('Ceremonial dresses'), link: ''}
  ];

  const setOneGridColumn = () => {
    setGridColsOptions('sm:grid-cols-2');
  };

  const setTwoGridColumns = () => {
    setGridColsOptions('sm:grid-cols-3');
  };

  const setThreeGridColumns = () => {
    setGridColsOptions('sm:grid-cols-4');
  };

  const scrollDownHandler = () => {
    pageInfoRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    isLoading ? <LoadingOverlay/> :
      <div>
        <div className={'relative flex flex-col w-full lg:h-screen'}>
          <span className={'relative w-full h-64 sm:h-80 md:h-96 lg:h-full overflow-hidden mt-12'}>
            <img src={'/images/ceremonial-dress-cover.jpg'} alt={'Ceremonial Dresses Cover Image'}
                 className={'w-full h-full object-cover block'}/>
            <ScrollDownButton onClick={scrollDownHandler}
                              className={'hidden lg:block lg:absolute z-10 left-0 right-0 mx-auto bottom-8 md:left-auto md:bottom-7 md:right-8'}/>
            <div
              className={'absolute w-full h-40 lg:h-80 bottom-0 bg-gradient-to-t from-[rgba(255,255,255,1)] to-[rgba(255,255,255,0)]'}>
            </div>
          </span>
          <span className={'w-full lg:absolute left-0 right-0 bottom-0 mx-auto lg:pt-12'}
                ref={pageInfoRef}>
            <div className={'flex flex-col items-center'}>
              <Typography
                className={'font-serif font-extralight drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)] text-white text-center text-4xl lg:text-8xl mb-5'}
                variant={'h1'}
              >
                {t('Ceremonial dresses')}
              </Typography>
              {/*<Breadcrumbs elements={breadcrumbPath}*/}
              {/*             className={'mx-auto w-full my-5 bg-white bg-opacity-100 drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]'}/>*/}
            </div>
          </span>
        </div>
        <div className={'container mx-auto'}>
          <AnimatePresence mode={'wait'}>
            <FadeInWhenVisible duration={1} fromDirection={'bottom'} delay={0.1} key={'collectionName'}>
              <Typography className={'text-deep-taupe text-center mx-5'}>
                {t('collection-screen-description-paragraph-1')}
              </Typography>
            </FadeInWhenVisible>
          </AnimatePresence>
          <AnimatePresence mode={'wait'}>
            <FadeInWhenVisible duration={1.4} fromDirection={'bottom'} delay={0.3} key={'collectionName'}>
              <Typography className={'text-deep-taupe text-center mx-5 mb-6 lg:mx-36'}>
                {t('collection-screen-description-paragraph-2')}
              </Typography>
            </FadeInWhenVisible>
          </AnimatePresence>
          <GridNumOfColumnsChanger onSelectedOneColumn={setOneGridColumn} onSelectTwoColumns={setTwoGridColumns}
                                   onSelectThreeColumns={setThreeGridColumns}
                                   className={'hidden sm:flex mb-12'}/>
        </div>
        <div className={`container mx-auto grid ${gridColsOptions} gap-4`}>
          {
            dresses.map((dress, index) => (
              <AnimatePresence mode={'wait'} key={index}>
                <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={0.5} key={'collectionName'}>
                  <CollectionItemPreviewCard name={''} image1={dress.images[0].large.url}
                                             image2={dress.images[1].large.url}
                                             onClick={() => openLightbox(dress.images)}
                                             className={'sm:h-[20rem] md:h-[25rem] lg:h-[30rem] transition-all duration-100 ease-out'}
                                             key={index}/>
                </FadeInWhenVisible>
              </AnimatePresence>
            ))
          }
        </div>
        <div className={'flex flex-col w-full items-center'}>
          {
            hasMoreToLoad() &&
            <Button className={'mt-12 normal-case'} disabled={isLoadingAdditional} onClick={loadMoreDresses}>
              {t('Show more')}
            </Button>
          }
        </div>
        <Lightbox
          open={lightboxOpen}
          //plugins={[Thumbnails, Zoom, Counter]}
          plugins={[Zoom, Counter]}
          close={closeLightbox}
          slides={lightboxImages}
          //thumbnails={{preload: 1, padding: 0, width: 'auto', showToggle: true}}
          zoom={{zoomInMultiplier: 5}}
        />
      </div>
  );
};

export default CeremonialDresses;
