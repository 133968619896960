import {useEffect, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import FadeInWhenVisible from '../../animations/fade-in-when-visible/FadeInWhenVisible';
import {Typography} from '@material-tailwind/react';

const TwoLayerCarousel = (props) => {

  const defaultAutoplayInterval = 3000;

  const {images, autoplayInterval, className} = props;

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
  };

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      handleNextSlide();
    }, autoplayInterval || defaultAutoplayInterval);

    return () => clearInterval(interval);
  }, [currentSlide, autoplayInterval]);

  return (
    <div className={`w-full max-h-screen overflow-hidden relative bg-white ${className ? className : ''}`}>
      <div className={'absolute w-full h-full overflow-hidden'}>
        <AnimatePresence initial={true} custom={currentSlide} mode={'wait'}>
          <motion.img
            key={currentSlide}
            custom={currentSlide}
            initial={{opacity: 0, scale: 1, filter: 'blur(20px)'}}
            animate={{opacity: 1, scale: 1, filter: 'blur(0)', transition: {duration: 0.8, ease: 'easeInOut'}}}
            exit={{opacity: 0, scale: 1, filter: 'blur(20px)', transition: {duration: 0.8, ease: 'easeInOut', delay: 0.12}}}
            src={images[currentSlide].backgroundImage}
            className={'w-full h-full object-cover object-top'}
          />
        </AnimatePresence>
      </div>
      <div
        className={'absolute w-full h-60 bottom-0 bg-gradient-to-t from-[rgba(255,255,255,1)] via-[rgba(255,255,255,0.8)] to-[rgba(255,255,255,0.02)]'}>
      </div>
      <AnimatePresence initial={true} custom={currentSlide} mode={'wait'}>
        <motion.img
          key={currentSlide}
          custom={currentSlide}
          initial={{opacity: 0, scale: 1, y: 10}}
          animate={{opacity: 1, scale: 1, y: 0, transition: {duration: 0.8, ease: 'easeInOut'}}}
          exit={{opacity: 0, scale: 1, y: 10, transition: {duration: 0.8, ease: 'easeInOut'}}}
          src={images[currentSlide].modelImage}
          className={'absolute w-full h-full object-cover'}
        />
      </AnimatePresence>
    </div>
  );
};

export default TwoLayerCarousel;
