import {Typography} from '@material-tailwind/react';
import CollectionItemPreviewCard from '../../shared/components/collection-item-preview-card/CollectionItemPreviewCard';
import React, {useEffect, useRef, useState} from 'react';
import WeddingDressCollectionService from '../../shared/services/wedding-dresses/WeddingDressCollectionService';
import slugify from 'react-slugify';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LoadingOverlay from '../../shared/components/loading-overlay/LoadingOverlay';
import GridNumOfColumnsChanger from '../../shared/components/grid-num-of-columns-changer/GridNumOfColumnsChanger';
import VideoPlayer from '../../shared/components/video-player/VideoPlayer';
import ScrollDownButton from '../../shared/components/scroll-down-button/ScrollDownButton';
import {ArrowLongRightIcon} from '@heroicons/react/20/solid';
import {AnimatePresence} from 'framer-motion';
import FadeInWhenVisible from '../../shared/animations/fade-in-when-visible/FadeInWhenVisible';
import ReactGA from 'react-ga4';

const CollectionDetails = (props) => {

  const {t, i18n} = useTranslation();

  const navigate = useNavigate();

  const {collectionName} = useParams();

  const [collection, setCollection] = useState({});
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [gridColsOptions, setGridColsOptions] = useState('sm:grid-cols-2');

  const pageInfoRef = useRef(null);

  const fetchData = async () => {
    const collection = await WeddingDressCollectionService.getWeddingDressCollection(collectionName);
    const collections = await WeddingDressCollectionService.getWeddingDressCollections();
    setCollection(collection);
    setCollections(collections);
    setIsLoading(false);
    ReactGA.send({hitType: 'pageview', page: `/wedding-dresses/${collection.slug}`, title: collection.name});
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [i18n.language]);

  const breadcrumbPath = [
    {name: t('Home'), link: '/'},
    {name: t('Wedding dresses'), link: '/wedding-dresses'},
    {name: collection.name, link: ''}
  ];

  const setOneGridColumn = () => {
    setGridColsOptions('sm:grid-cols-1');
  };

  const setTwoGridColumns = () => {
    setGridColsOptions('sm:grid-cols-2');
  };

  const setThreeGridColumns = () => {
    setGridColsOptions('sm:grid-cols-3');
  };

  const scrollDownHandler = () => {
    pageInfoRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const currentCollectionIndex = collections ? collections.findIndex(item => item.slug === collection.slug) : 0;

  const hasNextCollection = () => {
    return currentCollectionIndex < collections.length - 1;
  };

  const getNextCollection = () => {
    return collections[currentCollectionIndex + 1];
  };

  const openNextCollection = () => {
    navigate(`/wedding-dresses/${getNextCollection().slug}`);
  };

  return (
    isLoading ? <LoadingOverlay/> :
      <div>
        <div className={'relative flex flex-col w-full lg:h-screen'}>
          <span className={'relative w-full h-64 sm:h-80 md:h-96 lg:h-full overflow-hidden mt-12 lg:mt-0'}>
            {
              collection.video &&
              <VideoPlayer src={collection.video} className={'w-full h-full object-cover hidden lg:block'}/>
            }
            <img src={collection.coverImage} alt={'Ceremonial Dresses Cover Image'}
                 className={`w-full h-full object-cover block ${collection.video ? 'lg:hidden' : ''}`}/>
            <ScrollDownButton onClick={scrollDownHandler}
                              className={'hidden lg:block lg:absolute z-10 left-0 right-0 mx-auto bottom-8 md:left-auto md:bottom-7 md:right-8'}/>
            <div
              className={'absolute w-full h-40 lg:h-80 bottom-0 bg-gradient-to-t from-[rgba(255,255,255,1)] to-[rgba(255,255,255,0)]'}>
            </div>
          </span>
          <span className={'w-full lg:absolute left-0 right-0 bottom-0 mx-auto lg:pt-12'}
                ref={pageInfoRef}>
            <div className={'flex flex-col items-center'}>
              <Typography
                className={'font-serif font-extralight drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)] text-white text-center text-4xl lg:text-8xl mb-5'}
                variant={'h1'}
              >
                {collection.name}
              </Typography>
              {/*<Breadcrumbs elements={breadcrumbPath}*/}
              {/*             className={'mx-auto w-full my-5 bg-white bg-opacity-100 drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]'}/>*/}
            </div>
          </span>
        </div>
        <div className={'container mx-auto'}>
          <AnimatePresence mode={'wait'}>
            <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={0.2} key={'collectionName'}>
              <Typography className={'text-deep-taupe text-center mx-5 mb-6 lg:mx-36'}>
                {collection.description}
              </Typography>
            </FadeInWhenVisible>
          </AnimatePresence>
          <AnimatePresence mode={'wait'}>
            <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={0.5} key={'collectionName'}>
              <GridNumOfColumnsChanger onSelectedOneColumn={setOneGridColumn} onSelectTwoColumns={setTwoGridColumns}
                                       onSelectThreeColumns={setThreeGridColumns}
                                       className={'hidden sm:flex mb-12'}/>
            </FadeInWhenVisible>
          </AnimatePresence>

        </div>
        <div className={`container mx-auto grid grid-cols-1 ${gridColsOptions} gap-4`}>
          {
            collection.wedding_dresses && collection.wedding_dresses.map((dress, index) => (
              <AnimatePresence mode={'wait'} key={index}>
                <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={0.6} key={'collectionName'}>
                  <CollectionItemPreviewCard name={dress.name} image1={dress.images[0]}
                                             image2={dress.images[1]}
                                             link={slugify(dress.name)}
                                             key={dress.name}/>
                </FadeInWhenVisible>
              </AnimatePresence>
            ))
          }
        </div>
        {
          hasNextCollection() &&
          <div className={'grid md:flex justify-center items-center gap-2 md:gap-6 mt-10 md:mt-20 md:mb-10'}>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.2} delay={0.5} key={'nextCollection'} fromDirection={'left'}
                                 animationDistance={20}>
                <div className={'w-48 h-24'}>
                  <img src={getNextCollection().coverImage} alt={'Next collection'}
                       className={'w-full h-full object-cover'}/>
                </div>
              </FadeInWhenVisible>
            </AnimatePresence>
            <div
              className={'flex flex-col items-start cursor-pointer text-deep-taupe hover:text-quick-silver transition-all ease-out'}
              onClick={openNextCollection}>
          <span className={'w-full flex justify-between items-center'}>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.2} delay={0.5} key={'nextCollection'} fromDirection={'top'}
                                 animationDistance={20}>
                <Typography className={'md:text-xl'}>
                  {t('Next collection')}
                </Typography>
              </FadeInWhenVisible>
            </AnimatePresence>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.2} delay={0.5} key={'nextCollection'} fromDirection={'top'}
                                 animationDistance={20}>
                <ArrowLongRightIcon className={'h-4 md:h-6'}/>
              </FadeInWhenVisible>
            </AnimatePresence>
          </span>
              <AnimatePresence mode={'wait'}>
                <FadeInWhenVisible duration={1.2} delay={0.5} key={'nextCollection'} fromDirection={'bottom'}
                                   animationDistance={20}>
                  <Typography className={'font-normal font-serif text-2xl md:text-3xl'}>
                    {getNextCollection().name}
                  </Typography>
                </FadeInWhenVisible>
              </AnimatePresence>
            </div>
          </div>
        }
      </div>
  );
};

export default CollectionDetails;
