import {Card, CardHeader, CardBody, Typography} from '@material-tailwind/react';
import ZoomInAndOverlayImage from '../zoom-in-image/ZoomInAndOverlayImage';
import {useNavigate} from 'react-router-dom';

const CollectionPreviewCard = ({title, subTitle, image, link, className}) => {

  const navigate = useNavigate();

  const handleClick = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <Card
      shadow={false}
      className={`relative grid h-[18rem] sm:h-[20rem] lg:h-[30rem] xl:h-[40rem] w-full items-end justify-center overflow-hidden text-center cursor-pointer rounded-none ${className ? className : ''}`}
      onClick={handleClick}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color={'transparent'}
        className={`absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center`}
      >
        <ZoomInAndOverlayImage image={image} alt={'Collection image'} className={'absolute w-full'}/>
      </CardHeader>
      <CardBody
        className={'absolute w-full left-0 bottom-0 flex flex-col items-start gap-5 pl-3 pb-3 md:pl-6 md:pb-6 xl:pl-12 xl:pb-12 pointer-events-none'}>
        <span className={'flex flex-col items-start'}>
          <Typography
            className={'font-serif inline-block font-light text-white drop-shadow-[0_5px_5px_rgba(0,0,0,0.35)] text-5xl lg:text-8xl'}
          >
            {title.firstRow}
          </Typography>
          {
            title.secondRow &&
            <Typography
              className={'font-serif inline-block ml-10 -mt-2 font-light text-white drop-shadow-[0_5px_5px_rgba(0,0,0,0.35)] text-5xl lg:text-8xl'}
            >
              {title.secondRow}
            </Typography>
          }
        </span>
        {
          subTitle &&
          <Typography
            className={'w-full font-serif font-light text-center text-white drop-shadow-[0_3px_3px_rgba(0,0,0,0.40)] text-xl lg:text-2xl'}
          >
            {subTitle}
          </Typography>
        }
      </CardBody>
    </Card>
  );
};

export default CollectionPreviewCard;
