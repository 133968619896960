import {motion} from 'framer-motion';

const ContinuousMoveRight = (props) => {
  const {children, duration, className} = props;

  const variants = {
    moving: {
      x: [0, 50, 55, 0], // Values for the x-axis to create a continuous loop
      transition: {duration: duration, repeat: Infinity, ease: 'easeInOut'}
    }
  };

  return (
    <motion.div
      initial="moving"
      animate="moving"
      variants={variants}
      className={`${className ? className : ''}`}
    >
      {children}
    </motion.div>
  );
};

export default ContinuousMoveRight;