import {IconButton} from '@material-tailwind/react';

const RoundedIconButton = (props) => {
  const {onClick, children, className} = props;

  return (
    <IconButton onClick={onClick} className={`rounded-full bg-deep-taupe ${className ? className : ''}`}>
      {children}
    </IconButton>
  );
};

export default RoundedIconButton;