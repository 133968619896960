import {Button, DialogBody, DialogHeader, IconButton, Typography} from '@material-tailwind/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BustIcon} from '../../icons/bust-icon.svg';
import {ReactComponent as WaistIcon} from '../../icons/waist-icon.svg';
import {ReactComponent as HipsIcon} from '../../icons/hips-icon.svg';

const SizeChartDialog = ({onClose}) => {

  const {t} = useTranslation();

  const TABLE_HEAD = [t('Size'), 'EU', 'US', t('Bust'), t('Waist'), t('Hips'), t('Bicep')];

  const TABLE_ROWS = [
    ['XXS', 32, 0, 80, 60, 88, 26],
    ['XS', 34, 2, 84, 64, 92, 27],
    ['S', 36, 4, 88, 68, 96, 28],
    ['M', 38, 6, 92, 72, 100, 29],
    ['L', 40, 8, 96, 76, 104, 30],
    ['XL', 42, 10, 100, 80, 108, 31],
    ['XXL', 44, 12, 104, 84, 112, 32],
    ['XXXL', 46, 14, 108, 88, 116, 33],
    ['IVL', 48, 16, 112, 92, 120, 34],
    ['VI', 50, 18, 116, 96, 124, 35]
  ];

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <DialogHeader className={'px-4 py-2 lg:py-4 border-b border-deep-taupe justify-between'}>
        <Typography
          className={'font-serif font-normal tracking-wide text-deep-taupe'}
          variant={'h4'}
        >
          {t('Size guide')}
        </Typography>
        <IconButton variant={'text'} className={'rounded-full text-deep-taupe'} onClick={onCloseHandler}>
          <XMarkIcon className={'h-5'}/>
        </IconButton>
      </DialogHeader>
      <DialogBody className={'h-[35rem] p-4 lg:p-8 rounded-none overflow-y-scroll'}>
        <div className={'grid grid-cols-1 lg:grid-cols-2 gap-6'}>
          <div className={'w-full flex flex-col'}>
            <table className={'w-full min-w-max table-auto text-left border border-white-chocolate'}>
              <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className={'border border-deep-taupe px-1 py-2 lg:py-4 bg-white-chocolate'}
                  >
                    <Typography
                      variant={'small'}
                      className={'font-bold text-center lg:text-md text-deep-taupe leading-none'}
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
              </thead>
              <tbody>
              {TABLE_ROWS.map((row, index) => {
                return (
                  <tr key={`row-${index}`}>
                    {
                      row.map(column => (
                        <td className={'px-1 py-2 lg:py-4 border border-quick-silver'} key={`${row}-${column}`}>
                          <Typography
                            variant={'small'}
                            className={'font-bold text-center lg:text-md text-deep-taupe'}
                          >
                            {column}
                          </Typography>
                        </td>
                      ))
                    }
                  </tr>
                );
              })}
              </tbody>
            </table>
            <Typography className={'font-light text-xs tracking-wide text-deep-taupe mt-3'} variant={'paragraph'}>
              {t('mermaid-dress-description')}
            </Typography>
            <Typography className={'font-light text-xs tracking-wide text-deep-taupe'} variant={'paragraph'}>
              {t('princess-dress-description')}
            </Typography>
          </div>
          <div className={'flex flex-col justify-start gap-5 lg:pr-12'}>
            <Typography className={'font-bold text-deep-taupe lg:text-lg'}>
              {t('size-guide-description')}
            </Typography>
            <span className={'flex gap-2 lg:gap-3 justify-start'}>
              <span>
                <BustIcon className={'h-8 lg:h-10'}/>
              </span>
              <span className={'flex flex-col'}>
                <Typography className={'font-bold text-deep-taupe leading-none'}>
                  {t('Bust')}
                </Typography>
                <Typography className={'text-deep-taupe leading-tight'}>
                  {t('bust-guide-description')}
                </Typography>
              </span>
            </span>
            <span className={'flex gap-2 lg:gap-3 justify-start'}>
              <span>
                <WaistIcon className={'h-8 lg:h-10'}/>
              </span>
              <span className={'flex flex-col'}>
                <Typography className={'font-bold text-deep-taupe leading-none'}>
                  {t('Waist')}
                </Typography>
                <Typography className={'text-deep-taupe leading-tight'}>
                  {t('waist-guide-description')}
                </Typography>
              </span>
            </span>
            <span className={'flex gap-2 lg:gap-3 justify-start'}>
              <span>
                <HipsIcon className={'h-8 lg:h-10'}/>
              </span>
              <span className={'flex flex-col'}>
                <Typography className={'font-bold text-deep-taupe leading-none'}>
                  {t('Hips')}
                </Typography>
                <Typography className={'text-deep-taupe leading-tight'}>
                  {t('hips-guide-description')}
                </Typography>
              </span>
            </span>
          </div>
        </div>
      </DialogBody>
    </>
  );
};

export default SizeChartDialog;
