import {
  DialogBody,
  DialogHeader,
  IconButton,
  Tab, TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography
} from '@material-tailwind/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useTranslation} from 'react-i18next';

const MeasurementsDialog = ({onClose}) => {

  const {t} = useTranslation();

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  const tabs = [
    {
      key: 'zone-1',
      name: 'Oblast 1',
      image: '/images/zone1.png',
      content: [
        'Obim iznad grudi',
        'Obim ispod grudi',
        'Prednja dužina (do struka preko grudi)',
        'Prednja dužina (do struka relativno ka centru)'
      ]
    },
    {
      key: 'zone-2',
      name: 'Oblast 2',
      image: '/images/zone2.png',
      content: [
        'Dužina rukava',
        'Obim vrata',
        'Širina grudi',
        'Dužina haljine (od pazuha do poda + visina štikle)',
        'Dužina suknje (od struka do poda + visina štikle)',
        'Dužina leđa (od ramena do struka)',
        'Širina ramena',
        'Obim ruke',
        'Obim ručnog zgloba'
      ]
    },
    {
      key: 'zone-3',
      name: 'Oblast 3',
      image: '/images/zone3.png',
      content: [
        'Visina grudi',
        'Širina ramena (prednja)',
        'Obim grudi',
        'Obim struka',
        'Obim bedara',
        'Visina korseta (od pazuha do struka)',
        'Širina ramena (zadnja)',
        'Širina leđa'
      ]
    }
  ];

  return (
    <>
      <DialogHeader className={'px-4 py-2 lg:py-4 border-b border-deep-taupe justify-between'}>
        <Typography
          className={'font-serif font-normal tracking-wide text-deep-taupe'}
          variant={'h4'}
        >
          Kako uzeti mjere?
        </Typography>
        <IconButton variant={'text'} className={'rounded-full text-deep-taupe'} onClick={onCloseHandler}>
          <XMarkIcon className={'h-5'}/>
        </IconButton>
      </DialogHeader>
      <DialogBody className={'h-[32rem] rounded-none overflow-y-scroll'}>
        <Tabs value={'Oblast 1'} className={'lg:h-full'}>
          <TabsHeader>
            {
              tabs.map(({key, name}) => (
                <Tab key={key} value={name}>
                  {name}
                </Tab>
              ))
            }
          </TabsHeader>
          <TabsBody className={'lg:h-full'}>
            {
              tabs.map(({key, name, image, content}) => (
                <TabPanel key={key} value={name} className={'h-full'}>
                  <div className={'grid grid-cols-1 lg:grid-cols-2 gap-6 h-full'}>
                    <div className={'w-full flex flex-col justify-center h-full'}>
                      <img src={image}/>
                    </div>
                    <div className={'flex flex-col justify-start gap-2 lg:pr-12'}>
                      <Typography className={'font-serif text-deep-taupe text-lg lg:text-2xl'}>
                        Kako pravilno uzeti mjere?
                      </Typography>
                      <div className={'flex flex-col justify-center h-full lg:gap-1'}>
                        {
                          content.map((contentItem, index) => (
                            <Typography className={'font-serif text-deep-taupe'}>
                              {index + 1}. {contentItem}
                            </Typography>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </TabPanel>
              ))
            }
          </TabsBody>
        </Tabs>
      </DialogBody>
    </>
  );
};

export default MeasurementsDialog;
