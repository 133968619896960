import {Card, CardBody, CardHeader, Typography} from '@material-tailwind/react';
import SwitchImagesOnHover from '../switch-images-on-hover/SwitchImagesOnHover';
import {useNavigate} from 'react-router-dom';

const CollectionItemPreviewCard = ({name, image1, image2, link, onClick, className}) => {

  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    if (link) {
      navigate(link);
    }
  };

  return (
    <Card
      shadow={false}
      className={`relative grid h-[37rem] sm:h-[30rem] md:h-[35rem] lg:h-[40rem] w-full overflow-hidden cursor-pointer rounded-none ${className ? className : ''}`}
      onClick={handleClick}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color={'transparent'}
        className={`absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center`}
      >
        <SwitchImagesOnHover image1={image1} image2={image2} alt={name} className={'absolute w-full h-full'}/>
        <div
          className={'absolute w-full h-12 bottom-0 bg-gradient-to-t from-[rgba(255,255,255,1)] via-[rgba(255,255,255,0.2)] to-[rgba(255,255,255,0)]'}>
        </div>
      </CardHeader>
      <CardBody className={'absolute left-0 bottom-0 w-full pl-3 pb-3 md:pl-6 md:pb-6 pointer-events-none'}>
        <Typography
          className={'font-serif font-light text-white text-center drop-shadow-[0_2px_2px_rgba(0,0,0,0.55)] text-4xl lg:text-6xl'}
        >
          {name}
        </Typography>
      </CardBody>
    </Card>
  );
};

export default CollectionItemPreviewCard;
