import {IconButton} from '@material-tailwind/react';
import {ReactComponent as OneGridOutlineIcon} from '../../icons/1-grid-outline.svg';
import {ReactComponent as OneGridSolidIcon} from '../../icons/1-grid-solid.svg';
import {ReactComponent as TwoGridOutlineIcon} from '../../icons/2-grid-outline.svg';
import {ReactComponent as TwoGridSolidIcon} from '../../icons/2-grid-solid.svg';
import {ReactComponent as ThreeGridOutlineIcon} from '../../icons/3-grid-outline.svg';
import {ReactComponent as ThreeGridSolidIcon} from '../../icons/3-grid-solid.svg';
import {useState} from 'react';

const GridNumOfColumnsChanger = ({onSelectedOneColumn, onSelectTwoColumns, onSelectThreeColumns, className}) => {

  const [oneGridSelected, setOneGridSelected] = useState(false);
  const [twoGridSelected, setTwoGridSelected] = useState(true);
  const [threeGridSelected, setThreeGridSelected] = useState(false);

  const onOneColumnSelectedHandler = () => {
    if (onSelectedOneColumn) {
      onSelectedOneColumn();
    }
    setOneGridSelected(true);
    setTwoGridSelected(false);
    setThreeGridSelected(false);
  };

  const onTwoColumnsSelectedHandler = () => {
    if (onSelectTwoColumns) {
      onSelectTwoColumns();
    }
    setOneGridSelected(false);
    setTwoGridSelected(true);
    setThreeGridSelected(false);
  };

  const onThreeColumnsSelectedHandler = () => {
    if (onSelectThreeColumns) {
      onSelectThreeColumns();
    }
    setOneGridSelected(false);
    setTwoGridSelected(false);
    setThreeGridSelected(true);
  };

  return (
    <div className={`flex justify-center gap-4 ${className ? className : ''}`}>
      <IconButton className={'text-deep-taupe lg:hidden'} variant={'text'} onClick={onOneColumnSelectedHandler}>
        {
          oneGridSelected ?
            <OneGridSolidIcon className={'fill-deep-taupe h-4'}/> :
            <OneGridOutlineIcon className={'fill-deep-taupe h-4'}/>
        }
      </IconButton>
      <IconButton className={'text-deep-taupe'} variant={'text'} onClick={onTwoColumnsSelectedHandler}>
        {
          twoGridSelected ?
            <TwoGridSolidIcon className={'fill-deep-taupe h-4'}/> :
            <TwoGridOutlineIcon className={'fill-deep-taupe h-4'}/>
        }
      </IconButton>
      <IconButton className={'text-deep-taupe hidden lg:block'} variant={'text'} onClick={onThreeColumnsSelectedHandler}>
        {
          threeGridSelected ?
            <ThreeGridSolidIcon className={'fill-deep-taupe h-4'}/> :
            <ThreeGridOutlineIcon className={'fill-deep-taupe h-4'}/>
        }
      </IconButton>
    </div>
  );
};

export default GridNumOfColumnsChanger;