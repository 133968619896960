import {forwardRef, useEffect, useState} from 'react';
import {Typography} from '@material-tailwind/react';
import Carousel from '../carousel/Carousel';
import VideoPlayer from '../video-player/VideoPlayer';
import {AnimatePresence} from 'framer-motion';
import FadeInWhenVisible from '../../animations/fade-in-when-visible/FadeInWhenVisible';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as DecorativeCorner} from '../../assets/decorative-corner.svg';

const CollectionPreview = forwardRef(({collection, invertOrder, className}, ref) => {

  const [mobileView, setMobileView] = useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 970 ? setMobileView(false) : setMobileView(true);

  useEffect(() => {
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const navigate = useNavigate();

  const description = [collection.description];
  const images = collection.wedding_dresses.map(dress => ({src: dress.images[0], alt: ''})).slice(0, 3);

  const openCollectionHandler = () => {
    navigate(`/wedding-dresses/${collection.slug}`);
  };

  return (
    mobileView ?
      <div className={'w-full h-screen relative'} onClick={openCollectionHandler}>
        <div className={'absolute w-full h-full'}>
          <Carousel images={images} showArrows={false}/>
        </div>
        <div
          className={'absolute top-0 left-0 w-full h-full to-bg-black-80 inset-0 bg-gradient-to-t from-black/80 via-black/40'}>
        </div>
        <div className={'absolute bottom-0 w-full p-5'}>
          <Typography
            className={'font-serif inline-block font-light text-white drop-shadow-[0_5px_5px_rgba(0,0,0,0.35)] text-5xl mb-5'}
            variant={'h1'}
          >
            {collection.name}
          </Typography>
          {
            description.map((descriptionText, index) => (
              <Typography
                className={'text-white leading-snug line-clamp-4'}
                variant={'paragraph'}
                key={index}
              >
                {descriptionText}
              </Typography>
            ))
          }
        </div>
      </div>
      :
      <div
        className={`grid grid-cols-2 w-full h-screen overflow-hidden gap-4 xl:gap-20 ${className ? className : ''}`}
        ref={ref}>
        <div className={'relative w-full my-4 xl:my-12 p-7 overflow-hidden'}>
          <DecorativeCorner
            className={`w-24 h-24 absolute fill-deep-taupe top-0 opacity-20 ${invertOrder ? '-rotate-90 right-0' : 'rotate-180 left-0'}`}/>
          <div className={'flex flex-col w-full h-full justify-between overflow-hidden'}>
          <span className={`overflow-hidden ${invertOrder ? 'pr-12 xl:pr-24' : 'pl-12 xl:pl-24'}`}>
          <AnimatePresence mode={'wait'}>
            <FadeInWhenVisible duration={0.9} delay={0.2} key={'collectionName'}>
              <Typography
                className={'font-serif font-extralight leading-none text-deep-taupe mb-4 cursor-pointer'}
                variant={'h1'}
                onClick={openCollectionHandler}
              >
                {collection.name}
              </Typography>
            </FadeInWhenVisible>
          </AnimatePresence>
            {
              description.map((descriptionText, index) => (
                <AnimatePresence mode={'wait'} key={index}>
                  <FadeInWhenVisible duration={0.9} delay={0.4 + index * 0.2} key={'collectionDescription'}>
                    <Typography
                      className={'md:mt-6 text-deep-taupe mb-2 leading-snug text-justify cursor-pointer line-clamp-7'}
                      variant={'paragraph'}
                      onClick={openCollectionHandler}
                    >
                      {descriptionText}
                    </Typography>
                  </FadeInWhenVisible>
                </AnimatePresence>
              ))
            }
        </span>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={0.9} delay={0.6}>
                <div className={'h-64 xl:h-72 overflow-hidden cursor-pointer opacity-90'} onClick={openCollectionHandler}>
                  {
                    collection.video ?
                      <VideoPlayer src={collection.video} className={'w-full h-full object-cover'}
                                   onClick={openCollectionHandler}/> :
                      <img src={collection.coverImage} className={'h-full w-full object-cover'}/>
                  }
                </div>
              </FadeInWhenVisible>
            </AnimatePresence>
          </div>
          <DecorativeCorner
            className={`w-24 h-24 absolute z-100 bottom-0 fill-deep-taupe opacity-20 ${invertOrder ? 'rotate-90 left-0' : 'right-0'}`}/>
        </div>
        <div className={`w-full h-full overflow-hidden py-4 xl:py-12 ${invertOrder ? 'order-first' : ''}`}>
          <AnimatePresence mode={'wait'}>
            <FadeInWhenVisible duration={0.9} delay={0.6} fromDirection={'right'} className={'w-full h-full'}>
              <Carousel images={images} onClick={openCollectionHandler} className={'cursor-pointer'}/>
            </FadeInWhenVisible>
          </AnimatePresence>
        </div>
      </div>
  )
    ;
});

export default CollectionPreview;
