import 'react-medium-image-zoom/dist/styles.css';
//import {Thumbnails} from 'yet-another-react-lightbox/plugins';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import {useState} from 'react';

const ImageGallery = ({images, className}) => {

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const lightboxImages = images.map(image => ({
    src: image.original.url,
    width: image.original.width,
    height: image.original.height,
    srcSet: [
      {
        src: image.thumbnail.url,
        width: image.thumbnail.width,
        height: image.thumbnail.height
      },
      {
        src: image.large.url,
        width: image.large.width,
        height: image.large.height
      },
      {
        src: image.original.url,
        width: image.original.width,
        height: image.original.height
      }
    ]
  }));

  const openLightbox = (index) => {
    setImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <div className={`grid grid-cols-2 gap-2 md:gap-4 ${className ? className : ''}`}>
      {images.map((image, index) => (
        <div
          className={`relative cursor-pointer ${image.large.width > image.large.height ? 'col-span-2' : ''} ${image.className ? image.className : ''}`}
          key={index}
          onClick={() => openLightbox(index)}
        >
          <img
            src={image.large.url}
            alt={image.alt}
            className={'h-full w-full object-cover'}
          />
        </div>
      ))}
      <Lightbox
        open={lightboxOpen}
        index={imageIndex}
        //plugins={[Thumbnails, Zoom, Counter]}   AKO ZELIMO VRATITI THUMBNAILS SAMO OVU LINIJU KODA KORISTITI I IZBRISATI LINIJU ISPOD OVE... (bas fin opis... znam) i skinuti komentar s importa
        plugins={[Zoom, Counter]}
        close={closeLightbox}
        slides={lightboxImages}
        //thumbnails={{preload: 1, padding: 0, width: 'auto', showToggle: true}}
        zoom={{zoomInMultiplier: 5}}
      />
    </div>
  );
};

export default ImageGallery;
