import AxiosInstance from '../api-client/AxiosInstance';
import FileHelper from '../../helpers/file-helper/FileHelper';

const ENDPOINT = '/wedding-dress-collections';

const getWeddingDressCollections = (filters, populate = 'coverImage') => {
  const paramsObj = {populate: populate};
  if (filters) {
    paramsObj.filters = filters;
  }

  return AxiosInstance.get(ENDPOINT, {params: paramsObj})
    .then(response => response.data.data)
    .then(data => data.reverse().map(row => ({
      ...row.attributes,
      displayName: row.attributes.nameSecondRow ? {
        firstRow: row.attributes.nameFirstRow,
        secondRow: row.attributes.nameSecondRow
      } : {firstRow: row.attributes.name},
      coverImage: FileHelper.getFileUrl(row.attributes.coverImage.data.attributes.formats.large.url),
      video: row.attributes.video && row.attributes.video.data && FileHelper.getFileUrl(row.attributes.video.data.attributes.url),
      wedding_dresses: row.attributes.wedding_dresses && row.attributes.wedding_dresses.data.map(row => ({
        ...row.attributes,
        images: row.attributes.images && row.attributes.images.data.map(row => FileHelper.getFileUrl(row.attributes.formats.large.url))
      }))
    })));
};

const getWeddingDressCollection = (slug, populate = 'coverImage,wedding_dresses,wedding_dresses.images,video') => {
  return AxiosInstance.get(`${ENDPOINT}/${slug}`, {params: {populate: populate}})
    .then(response => response.data.data)
    .then(data => ({
      ...data.attributes,
      coverImage: data.attributes.coverImage ? FileHelper.getFileUrl(data.attributes.coverImage.data.attributes.url) : undefined,
      video: data.attributes.video && data.attributes.video.data && FileHelper.getFileUrl(data.attributes.video.data.attributes.url),
      wedding_dresses: data.attributes.wedding_dresses && data.attributes.wedding_dresses.data.map(row => ({
        ...row.attributes,
        images: row.attributes.images && row.attributes.images.data.map(row => FileHelper.getFileUrl(row.attributes.formats.large.url))
      }))
    }));
};

const WeddingDressCollectionService = {
  getWeddingDressCollections,
  getWeddingDressCollection
};

export default WeddingDressCollectionService;
