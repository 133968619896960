import {useTranslation} from 'react-i18next';
import React, {useEffect, useRef, useState} from 'react';
import LoadingOverlay from '../../shared/components/loading-overlay/LoadingOverlay';
import {Dialog, Typography} from '@material-tailwind/react';
import ImageGallery from '../../shared/components/image-gallery/ImageGallery';
import Button from '../../shared/components/button/Button';
import InspirationImagesService from '../../shared/services/inspiration-images/InspirationImagesService';
import ScrollDownButton from '../../shared/components/scroll-down-button/ScrollDownButton';
import FadeInWhenVisible from '../../shared/animations/fade-in-when-visible/FadeInWhenVisible';
import {AnimatePresence} from 'framer-motion';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import UploadYourStoryForm from '../../shared/components/upload-your-story/UploadYourStoryForm';
import ReactGA from 'react-ga4';
import UploadYourStoryDialog from '../../shared/components/upload-your-story/UploadYourStoryDialog';

const Inspiration = () => {

  ReactGA.send({hitType: 'pageview', page: '/inspiration', title: 'Inspiration'});

  const {t, i18n} = useTranslation();

  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAdditional, setIsLoadingAdditional] = useState(false);
  const [page, setPage] = useState(1);
  const [totalImages, setTotalImages] = useState();

  const pageInfoRef = useRef(null);

  const fetchData = async (page) => {
    const fetchedData = await InspirationImagesService.getInspirationImages(page, 12);

    const fetchedImages = fetchedData.data.map(row => row.image);

    setTotalImages(fetchedData.meta.pagination.total);

    setImages(prevState => ([...prevState, ...fetchedImages]));
    setIsLoading(false);
  };

  const loadMoreImages = async () => {
    setIsLoadingAdditional(true);
    await fetchData(page + 1);
    setPage(page + 1);
    setIsLoadingAdditional(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [i18n.language]);

  const breadcrumbPath = [
    {name: t('Home'), link: '/'},
    {name: t('Inspiration'), link: ''}
  ];

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogState = () => setDialogOpen(prevState => !prevState);

  const scrollDownHandler = () => {
    pageInfoRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const hasMoreToLoad = () => {
    return images.length < totalImages;
  };

  return (
    <div>
      {
        isLoading && <LoadingOverlay/>
      }
      <div className={'relative flex flex-col w-full lg:h-screen'}>
          <span className={'relative w-full h-64 sm:h-80 md:h-96 lg:h-full overflow-hidden'}>
            <div className={'relative w-full h-full hidden lg:block'}>
              <div
                className={'absolute left-20 bottom-0 w-72 overflow-hidden drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] hidden md:block'}>
                <AnimatePresence mode={'wait'}>
                  <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={0.2} key={'collectionName'}>
                    <img src={'/images/inspo2.jpg'} className={'rounded-lg'}/>
                  </FadeInWhenVisible>
                </AnimatePresence>
              </div>

              <div
                className={'absolute -z-10 right-60 top-12 w-72 overflow-hidden drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] '}>
                <AnimatePresence mode={'wait'}>
                  <FadeInWhenVisible duration={1.6} fromDirection={'top'} delay={0.9} key={'collectionName'}>
                    <img src={'/images/inspo5.jpg'} className={'rounded-lg'}/>
                  </FadeInWhenVisible>
                </AnimatePresence>
              </div>

              <div
                className={'absolute left-44 md:left-10 top-0 w-72 overflow-hidden drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] '}>
                <AnimatePresence mode={'wait'}>
                  <FadeInWhenVisible duration={1.6} fromDirection={'top'} delay={1.8} key={'collectionName'}>
                    <img src={'/images/inspo1.jpg'} className={'rounded-lg'}/>
                  </FadeInWhenVisible>
                </AnimatePresence>
              </div>

              <div
                className={'absolute right-64 md:right-20 top-24 md:top-20 w-1/3 md:w-60 overflow-hidden drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] '}>
                <AnimatePresence mode={'wait'}>
                  <FadeInWhenVisible duration={1.8} fromDirection={'top'} delay={2.6} key={'collectionName'}>
                    <img src={'/images/inspo6.jpg'} className={'rounded-lg'}/>
                  </FadeInWhenVisible>
                </AnimatePresence>
              </div>

              <div
                className={'absolute right-6 -bottom-32 w-80 overflow-hidden drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] hidden md:block'}>
                <AnimatePresence mode={'wait'}>
                  <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={3.1} key={'collectionName'}>
                    <img src={'/images/inspo4.jpg'} className={'rounded-lg'}/>
                  </FadeInWhenVisible>
                </AnimatePresence>
              </div>

              <div
                className={'absolute left-64 md:left-60 top-24 w-1/3 md:w-60 overflow-hidden drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] '}>
                <AnimatePresence mode={'wait'}>
                  <FadeInWhenVisible duration={2} fromDirection={'top'} delay={3.9} key={'collectionName'}>
                    <img src={'/images/inspo3.jpg'} className={'rounded-lg'}/>
                  </FadeInWhenVisible>
                </AnimatePresence>
              </div>

              <div
                className={'absolute right-1/3 bottom-0 w-1/3 h-48 md:h-3/4 overflow-hidden rounded-lg drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]'}>
                <AnimatePresence mode={'wait'}>
                  <FadeInWhenVisible duration={1.5} delay={4.6} key={'collectionName'}>
                    <img src={'/images/inspo.jpg'} className={'rounded-lg object-cover'}/>
                  </FadeInWhenVisible>
                </AnimatePresence>
              </div>

            </div>
            <img src={'/images/inspirationHeroImage.jpg'} alt={'Inspiration page cover image'}
                 className={`w-full h-full object-cover lg:hidden`}/>
            <ScrollDownButton onClick={scrollDownHandler}
                              className={'hidden lg:block lg:absolute z-10 left-0 right-0 mx-auto bottom-8 md:left-auto md:bottom-7 md:right-8'}/>
            <div
              className={'absolute w-full h-40 lg:h-80 bottom-0 bg-gradient-to-t from-[rgba(255,255,255,1)] to-[rgba(255,255,255,0)]'}>
            </div>
          </span>
        <span className={'w-full lg:absolute left-0 right-0 bottom-0 mx-auto -mt-20 lg:-mb-10 lg:pt-12'}
              ref={pageInfoRef}>
            <div className={'flex flex-col items-center'}>
              <Typography
                className={'font-serif font-extralight drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)] text-white text-center text-4xl lg:text-8xl mb-5'}
                variant={'h1'}
              >
                {t('Stories of our brides')}
              </Typography>
              {/*<Breadcrumbs elements={breadcrumbPath}*/}
              {/*             className={'mx-auto w-full my-5 bg-white bg-opacity-100 drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]'}/>*/}
              <div className={'h-10'}>

              </div>
            </div>
          </span>
      </div>
      <div className={'container mx-auto flex flex-col items-center lg:mt-10'}>
        <div className={'px-4 md:px-0 md:w-1/2 flex-col gap-4 mb-12 hidden lg:flex'}>
          <Typography
            className={'font-serif font-normal tracking-wide text-deep-taupe text-center'}
            variant={'h4'}
          >
            Pošaljite Vašu priču
          </Typography>
          <Typography className={'text-deep-taupe text-center mb-5'} variant={'paragraph'}>
            {t('inspiration-hero-text')}
          </Typography>
          <GoogleReCaptchaProvider
            reCaptchaKey={RECAPTCHA_SITE_KEY}
            container={{element: 'reCaptchaForm'}}
          >
            <UploadYourStoryForm/>
            <div className={'flex justify-center mt-10'} id={'reCaptchaForm'}>
            </div>
          </GoogleReCaptchaProvider>
        </div>
        <div className={'lg:hidden mb-12'}>
          <GoogleReCaptchaProvider
            reCaptchaKey={RECAPTCHA_SITE_KEY}
            container={{element: 'reCaptchaFormDialog'}}
          >
            <Button className={'mt-1 normal-case'} onClick={handleDialogState} fullWidth>
              Pošaljite Vašu priču
            </Button>
            <div className={'flex justify-center mt-10'} id={'reCaptchaFormDialog'}>
            </div>
            <Dialog open={dialogOpen} handler={handleDialogState} className={'rounded-none w-full'} size={'xl'}>

              <UploadYourStoryDialog onClose={handleDialogState}/>
            </Dialog>
          </GoogleReCaptchaProvider>
        </div>

        <ImageGallery images={images} className={'grid-cols-2 lg:grid-cols-4'}/>
        {
          hasMoreToLoad() &&
          <Button className={'mt-12 normal-case'} disabled={isLoadingAdditional} onClick={loadMoreImages}>
            {t('Show more')}
          </Button>
        }
      </div>
    </div>
  );
};

export default Inspiration;
