import axios from 'axios';
import i18n from 'i18next';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const AxiosInstance = axios.create({
  baseURL: `${BACKEND_URL}/api`,
  timeout: 5000
});

AxiosInstance.interceptors.request.use(
  (config) => {
    if (!config.params) {
      config.params = {};
    }

    config.params.locale = i18n.language;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('Response error:', error);

    return Promise.reject(error);
  }
)

export default AxiosInstance;
