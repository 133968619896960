const VideoPlayer = ({src, onClick, className}) => {

  const onCLickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <video className={className ? className : ''} onClick={onCLickHandler} autoPlay loop muted>
      <source src={src} type="video/mp4"/>
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;
