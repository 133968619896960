const GoogleMap = ({location, className}) => {
  return (
    <iframe
      title="Google Map"
      className={className}
      src={location}
      style={{border: 0}}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade">
    </iframe>
  );
};

export default GoogleMap;