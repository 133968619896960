import RoundedIconButton from '../rounded-icon-button/RoundedIconButton';

const ScrollDownButton = (props) => {
  const {onClick, className} = props;

  return (
    <div className={`w-fit ${className ? className : ''}`}>
      <RoundedIconButton onClick={onClick}>
        <i className="fas fa-arrow-down"/>
      </RoundedIconButton>
    </div>
  );
};

export default ScrollDownButton;