import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Collapse, IconButton, Navbar as MaterialNavbar, Typography} from '@material-tailwind/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {useTranslation} from 'react-i18next';

const NavList = ({onItemClick, fromItem, itemCount}) => {

  const {t} = useTranslation();

  const pages = [
    {
      name: t('Wedding dresses'),
      link: '/wedding-dresses'
    },
    {
      name: t('Ceremonial dresses'),
      link: '/ceremonial-dresses'
    },
    {
      name: t('Inspiration'),
      link: '/inspiration'
    },
    {
      name: t('About Us'),
      link: '/about-us'
    },
    {
      name: t('Contact Us'),
      link: '/contact-us'
    }
  ];

  const handleOnItemClicked = () => {
    if (onItemClick) {
      onItemClick();
    }
  };

  const filteredPages = pages.slice(fromItem, fromItem + itemCount);

  return (
    <ul className="my-2 flex flex-col gap-2 xl:mb-0 xl:mt-0 xl:flex-row xl:items-center xl:gap-6">
      {
        filteredPages.map(page => (
          <Typography
            as="li"
            variant="paragraph"
            color="gray"
            className="p-1 font-normal font-serif"
            key={page.link}
          >
            <Link to={page.link} className="flex items-center hover:text-deep-taupe transition-colors"
                  onClick={handleOnItemClicked}>
              {page.name}
            </Link>
          </Typography>
        ))
      }
    </ul>
  );
};

const Navbar = (props) => {
  const [openNav, setOpenNav] = useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 1140 && setOpenNav(false);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const closeNavbar = () => {
    setOpenNav(false);
  };

  return (
    <MaterialNavbar
      className={`mx-auto max-w-full px-0 py-3 bg-white bg-opacity-80 border-b-0 rounded-none shadow-none ${props.className}`}>
      <div className="flex items-center justify-center gap-10 max-w-screen-2xl mx-auto text-gray-900">
        <div className="hidden xl:block">
          <NavList fromItem={0} itemCount={2}/>
        </div>
        <Link to={'/'} className={'flex justify-center items-center'}>
          <div
            className={'font-semibold text-deep-taupe uppercase text-xs md:text-sm tracking-[.2em] md:tracking-[.4em]'}>
            Studio
          </div>
          <img src="/logo.svg" className={'w-5 md:w-7 ml-2 mr-3 -mt-1'}/>
          <span
            className={'font-semibold text-deep-taupe uppercase text-xs md:text-sm tracking-[.2em] md:tracking-[.4em]'}>
            Divine
          </span>
        </Link>
        <div className="hidden xl:block">
          <NavList fromItem={2} itemCount={3}/>
        </div>
      </div>
      <span className={'flex justify-center items-center absolute right-0 top-0 h-11 w-11'}>
        <IconButton
          variant="text"
          className="h-6 w-6 text-deep-taupe hover:bg-transparent focus:bg-transparent active:bg-transparent xl:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2}/>
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2}/>
          )}
        </IconButton>
        </span>
      <Collapse open={openNav}>
        <NavList onItemClick={closeNavbar} fromItem={0} itemCount={5}/>
      </Collapse>
    </MaterialNavbar>
  );
};

export default Navbar;
