import {Input, Typography} from '@material-tailwind/react';
import {useTranslation} from 'react-i18next';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import React, {useRef, useState} from 'react';
import InspirationImagesService from '../../services/inspiration-images/InspirationImagesService';
import Button from '../button/Button';
import FadeInWhenVisible from '../../animations/fade-in-when-visible/FadeInWhenVisible';
import {AnimatePresence} from 'framer-motion';
import ReactGA from 'react-ga4';

const UploadYourStoryForm = () => {
  const {t, i18n} = useTranslation();

  const {executeRecaptcha} = useGoogleReCaptcha();

  const getReCaptchaToken = async () => {
    if (!executeRecaptcha) {
      return;
    }

    return executeRecaptcha('form_submission');
  };

  const inputFile = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleFileSelectionOpen = () => {
    inputFile.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const maxSize = 3 * 1024 * 1024;
    const maxFiles = 5;

    if (selectedFiles.length > maxFiles) {
      alert(`Možete poslati najviše ${maxFiles} fotografija.`);
      return;
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].size > maxSize) {
        alert(`Fotografija ${selectedFiles[i].name} je veća od dozvoljenih 3MB.`);
        return;
      }
    }

    setSelectedFiles(event.target.files);
  };

  const handleSubmit = async () => {
    setIsSending(true);

    const token = await getReCaptchaToken();

    const formData = new FormData();

    formData.append('name', name);
    formData.append('email', email);
    formData.append('token', token);

    if (selectedFiles) {
      for (const file of selectedFiles) {
        formData.append('photos', file);
      }
    }

    try {
      await InspirationImagesService.sendInspirationImages(formData);
      ReactGA.event({
        category: 'Upload Your Story form',
        action: 'form_submission',
      });
    } catch (error) {
      ReactGA.event({
        category: 'Upload Your Story form',
        action: 'form_submission_error',
      });
    }

    setIsSending(false);
    setIsSent(true);
  };

  const addPhotosButtonText = selectedFiles ? Object.values(selectedFiles).map(file => file.name).join(', ') : 'Izaberi slike';

  const hasImages = () => {
    return selectedFiles ? selectedFiles.length > 0 : false;
  };

  return (
    <form className={'w-full flex flex-col items-center gap-4'}>
      <Input
        size={'lg'}
        label={t('Name')}
        name={'name'}
        color={'brown'}
        onChange={(event) => setName(event.target.value)}
        disabled={isSent}
      />
      <Input
        size={'lg'}
        label={t('Email')}
        name={'email'}
        color={'brown'}
        onChange={(event) => setEmail(event.target.value)}
        disabled={isSent}
      />
      <input className={'hidden'} type={'file'} name={'files'} ref={inputFile} multiple onChange={handleFileChange}/>
      <span className={'w-full'}>
        <Button className={'normal-case mb-2'} onClick={handleFileSelectionOpen} fullWidth disabled={isSent}>
          {addPhotosButtonText}
        </Button>
        <Typography className={'self-start text-xs text-deep-taupe'}>
          *Maksimalna veličina slike 3MB, do 5 slika
        </Typography>
      </span>
      <Button className={'mt-1 normal-case'} onClick={handleSubmit} disabled={isSending || isSent || !hasImages()}
              fullWidth>
        {t('Send')}
      </Button>
      {
        isSent &&
        <AnimatePresence mode={'wait'}>
          <FadeInWhenVisible duration={1} fromDirection={'bottom'} animationDistance={10} delay={0.3}
                             key={'collectionName'}>
            <Typography className={'font-serif font-extralight text-deep-taupe my-3'}>
              Hvala Vam što ste sa nama podijelili Vaše nezaboravne trenutke!
            </Typography>
          </FadeInWhenVisible>
        </AnimatePresence>
      }
    </form>
  );
};

export default UploadYourStoryForm;
