import {Spinner} from '@material-tailwind/react';

const LoadingOverlay = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white z-50">
      <Spinner className={'h-16 w-16'} color={'gray'}/>
    </div>
  );
};

export default LoadingOverlay;
