import {Typography} from '@material-tailwind/react';
import Breadcrumbs from '../../shared/components/breadcrumbs/Breadcrumbs';
import CollectionPreviewCard from '../../shared/components/collection-preview-card/CollectionPreviewCard';
import React, {useEffect, useState} from 'react';
import WeddingDressCollectionService from '../../shared/services/wedding-dresses/WeddingDressCollectionService';
import slugify from 'react-slugify';
import {useTranslation} from 'react-i18next';
import LoadingOverlay from '../../shared/components/loading-overlay/LoadingOverlay';
import FadeInWhenVisible from '../../shared/animations/fade-in-when-visible/FadeInWhenVisible';
import {AnimatePresence} from 'framer-motion';
import ReactGA from 'react-ga4';

const Collections = () => {

  ReactGA.send({hitType: 'pageview', page: '/wedding-dresses', title: 'Wedding Dresses'});

  const {t, i18n} = useTranslation();

  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const collections = await WeddingDressCollectionService.getWeddingDressCollections();
    setCollections(collections);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [i18n.language]);

  return (
    <div>
      {
        isLoading && <LoadingOverlay/>
      }
      <div className={'container mx-auto flex flex-col gap-4 mt-20'}>
        {
          collections.map((collection, index) =>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={0.5} key={'collectionName'}>
                <CollectionPreviewCard title={collection.displayName}
                                       subTitle={collection.collectionYear ? `${t('Collection')} ${collection.collectionYear}` : undefined}
                                       image={collection.coverImage}
                                       link={slugify(collection.name)} key={collection.name}/>
              </FadeInWhenVisible>
            </AnimatePresence>

          )
        }
        <span className={'flex flex-col m-2 xl:mx-28 xl:my-10 items-center'}>
          <img src="/logo.svg" className={'w-8 md:w-12 mb-6'}/>
          <AnimatePresence mode={'wait'}>
                <FadeInWhenVisible duration={1.8} fromDirection={'bottom'} delay={1.2} key={'collectionName'}>
                  <Typography className={'lg:text-xl text-deep-taupe text-center mb-3'}>
            {t('collection-screen-description-paragraph-1')}
          </Typography>
          <Typography className={'lg:text-xl text-deep-taupe text-center'}>
            {t('collection-screen-description-paragraph-2')}
          </Typography>
                </FadeInWhenVisible>
              </AnimatePresence>
        </span>
      </div>
    </div>
  );
};

export default Collections;
