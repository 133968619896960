import {Typography} from '@material-tailwind/react';
import {useTranslation} from 'react-i18next';
import FadeInWhenVisible from '../../animations/fade-in-when-visible/FadeInWhenVisible';
import {AnimatePresence} from 'framer-motion';

const InstagramGallery = () => {

  const {t} = useTranslation();

  const handleClick = () => {
    window.open('http://instagram.com/studiodivine_vjencanice/', '_blank');
  };

  return (
    <div className={'overflow-hidden w-full grid grid-cols-2 gap-5 lg:my-20'}>
      <span className={'flex flex-col items-end ml-10 lg:ml-0 md:items-end'}>
        <span className={'h-60 pt-10'}>
          <div className={'relative flex flex-col items-start left-24 md:left-0'}>
          <Typography className={'text-xl text-deep-taupe md:-ml-10 lg:-mb-1'}>
            {t('Follow us on')}
          </Typography>
          <Typography className={'font-serif text-4xl lg:text-5xl text-deep-taupe ml-5 lg:mr-20'}>
            {t('Instagram')}
          </Typography>
            </div>
        </span>
        <span className={'relative flex flex-col items-start bottom-28 left-24 md:left-0 md:right-24 lg:right-14'}>
          <div className={'rounded-lg w-60 lg:w-96 h-44 lg:h-64 overflow-hidden drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] cursor-pointer'}
               onClick={handleClick}>
            <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.6} fromDirection={'left'} delay={0.5} key={'collectionName'}>
                <img src={'/images/ig1.jpg'}/>
              </FadeInWhenVisible>
            </AnimatePresence>
          </div>
        </span>
        <span className={'relative flex flex-col items-start top-48 md:-top-14 md:right-20 lg:right-44'}>
        <div className={'rounded-lg w-36 h-36 md:w-60 md:h-72 overflow-hidden cursor-pointer drop-shadow-[0_10px_10px_rgba(0,0,0,0.15)]'}
             onClick={handleClick}>
          <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.6} fromDirection={'left'} delay={0.6} key={'collectionName'}>
                <img src={'/images/ig6.jpg'}/>
              </FadeInWhenVisible>
            </AnimatePresence>
        </div>
          </span>
      </span>
      <span className={'relative flex flex-col items-start'}>
        <div className={'rounded-lg w-60 md:w-80 lg:w-96 h-60 md:h-64 overflow-hidden mt-96 -ml-28 md:ml-0 md:mt-10 lg:mt-0 mb-3 cursor-pointer drop-shadow-[0_10px_10px_rgba(0,0,0,0.15)]'}
             onClick={handleClick}>
          <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.6} fromDirection={'right'} delay={0.8} key={'collectionName'}>
                <img src={'/images/ig3.jpg'}/>
              </FadeInWhenVisible>
            </AnimatePresence>
        </div>
        <span className={'relative right-5 md:right-20 bottom-96 md:bottom-0 flex flex-col items-start'}>
        <div className={'rounded-lg w-40 md:w-60 h-40 md:h-72 lg:mt-4 overflow-hidden cursor-pointer drop-shadow-[0_10px_10px_rgba(0,0,0,0.10)]'}
             onClick={handleClick}>
          <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.6} fromDirection={'bottom'} delay={0.7} key={'collectionName'}>
                <img src={'/images/ig4.jpg'}/>
              </FadeInWhenVisible>
            </AnimatePresence>
        </div>
          </span>
        <div
          className={'rounded-lg w-44 lg:w-72 h-44 lg:h-64 md:left-28 lg:mt-80 lg:ml-10 md:top-64 lg:top-20 overflow-hidden absolute drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] cursor-pointer hidden md:block'}
          onClick={handleClick}>
          <AnimatePresence mode={'wait'}>
              <FadeInWhenVisible duration={1.6} fromDirection={'right'} delay={0.9} key={'collectionName'}>
                <img src={'/images/ig5.jpg'}/>
              </FadeInWhenVisible>
            </AnimatePresence>
        </div>
      </span>
    </div>
  );
};

export default InstagramGallery;
