import {Typography} from '@material-tailwind/react';

const ItemProperty = ({name, values, className}) => {
  return (
    <div className={`w-full flex flex-col items-center ${className ? className : ''}`}>
      <Typography
        className={'font-medium tracking-widest text-deep-taupe text-xs uppercase mb-3'}
        variant={'paragraph'}
      >
        {name}
      </Typography>
      <div className={'w-full flex justify-center'}>
        {
          values.map(value => (
            <Typography
              className={'font-light tracking-widest text-deep-taupe text-xs uppercase mx-1'}
              variant={'paragraph'}
              key={`${name}-${value}`}
            >
              {value}
            </Typography>
          ))
        }
      </div>
    </div>
  );
};

export default ItemProperty;