import {Button as MaterialButton} from '@material-tailwind/react';

const Button = ({children, size, disabled, variant, onClick, fullWidth, className}) => {

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <MaterialButton
      className={`font-serif font-light tracking-widest bg-transparent text-deep-taupe border border-deep-taupe hover:bg-deep-taupe hover:text-white whitespace-nowrap ${className ? className : ''}`}
      variant={variant} size={size} disabled={disabled} fullWidth={fullWidth}
      onClick={onClickHandler}>
      {children}
    </MaterialButton>
  );
};

export default Button;